import './css/market.css'
import axios from 'axios';
import Page from './Page';
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faStar, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, change_OKX_S_Favorite, change_OKX_F_Favorite, change_Binance_S_Favorite, change_Binance_F_Favorite, checkLogin, check_Error, change_Bybit_F_Favorite, change_Bybit_S_Favorite } from '../store';
import { SyncLoader } from "react-spinners";
import IMG from "./img"
import { useTranslation } from "react-i18next";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "./common/function"
import Modal from './message/modal';

import NewMarketTop from './common/C_market/newMarketTop';

const maxFirstPage = 15;
let BS_filterSymbol = []
let BF_filterSymbol = []
let BS_filterData = []
let BF_filterData = []
let Type = "";

let spotPreData = []
let futuresPreData = []

function Market() {
    const { t } = useTranslation();
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let BinanceS = useSelector((state) => { return state.Binance_coinS });
    let BinanceF = useSelector((state) => { return state.Binance_coinF });
    let OKXS = useSelector((state) => { return state.OKX_coinS });
    let OKXF = useSelector((state) => { return state.OKX_coinF });
    let BybitS = useSelector((state) => { return state.Bybit_coinS });
    let BybitF = useSelector((state) => { return state.Bybit_coinF });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Bybit_Mode = useSelector((state) => { return state.Bybit_Mode.value })

    const darkname = dark ? 'dark' : "";

    let USDTSymbolS = [];
    let USDTSymbolF = [];

    if (SelectType === 0) {
        USDTSymbolS = OKXS;
        USDTSymbolF = OKXF;
        Type = "okx";
    }
    else if (SelectType === 1) {
        USDTSymbolS = BinanceS;
        USDTSymbolF = BinanceF;
        Type = "binance";
    }
    else {
        USDTSymbolS = BybitS;
        USDTSymbolF = BybitF;
        Type = "bybit";
    }

    useEffect(() => {
        spotPreData = []
        futuresPreData = []
    }, [SelectType])

    let dispatch = useDispatch();

    const [topbtn1, setTopbtn1] = useState(1);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [windowtype1, setWindowtype1] = useState(true);
    const [spinner, setSpinner] = useState(true)

    const onChange = (e) => {
        setSearch(e.target.value)
        setPage(1)
    }

    const onSelectType = (e) => {
        setPage(1)
        handleClick()
        dispatch(checkSelectType(e))
    }

    if (topbtn1 === 1) {
        BS_filterSymbol = USDTSymbolS.filter((p) => {
            return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }
    else {
        BF_filterSymbol = USDTSymbolF.filter((p) => {
            return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1300) setWindowtype1(true)
            else setWindowtype1(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, []);

    useEffect(() => {
        handleClick()
    }, [])

    const handleClick = () => {
        setSpinner(true);

        setTimeout(() => {
            setSpinner(false);
        }, 400);
    };

    useEffect(() => {
        document.title = `${API_Address == "rf" ? "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe" : "goEx│One-Stop Trading Platform - Crypto Trading Universe"}`;
    }, [API_Address]);

    return (
        <div className={`market ${darkname} ${API_Address == "dev" ? "font-family" : ""}`}
        // style={{ paddingLeft: API_Address == "dev" ? "50px" : "" }}
        >
            <center>
                {
                    API_Address == "dev"
                        ?
                        <NewMarketTop onSelectType={onSelectType} USDTSymbolS={USDTSymbolS} USDTSymbolF={USDTSymbolF} windowtype1={windowtype1} Type={Type} />
                        :
                        ""
                }
                <div className='market-box'>
                    {
                        API_Address == "dev"
                            ?
                            ""
                            :
                            <div className='market-top1'>
                                <div className={SelectType === 1 ? `market-top1-btn current ${darkname}` : `market-top1-btn ${darkname}`} onClick={() => onSelectType(1)}>{t('exchange_0')}</div>
                                <div className={SelectType === 0 ? `market-top1-btn current ${darkname}` : `market-top1-btn ${darkname}`} onClick={() => onSelectType(0)}>{t('exchange_1')}</div>
                                <div className={SelectType === 2 ? `market-top1-btn current ${darkname}` : `market-top1-btn ${darkname}`} onClick={() => onSelectType(2)}>{t('exchange_2')}</div>
                            </div>
                    }
                    <div className='market-top2'>
                        <div className={`market-title ${darkname}`}>{t('header_0')}</div>
                        <div className={`market-search ${darkname}`}>
                            <div className='market-search-left'>
                                <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: "15px" }} />
                            </div>
                            <input type="text" className={`market-search-input ${darkname}`} placeholder={`${t('market_0')}`} value={search} onChange={onChange} />
                            {
                                search && search.length === 0
                                    ?
                                    ""
                                    :
                                    <FontAwesomeIcon icon={faCircleXmark} className='market-search-input-x' onClick={() => setSearch("")} />
                            }
                        </div>
                    </div>
                    <div className={`market-top3 ${darkname}`}>
                        <div className={`market-top3-box ${darkname}`}>
                            <div className={topbtn1 === 1 ? `market-top3-tab current ${darkname}` : 'market-top3-tab'} onClick={() => { setTopbtn1(1); setPage(1) }}><span>{t('market_1')}</span></div>
                            <div className={topbtn1 === 2 ? `market-top3-tab current ${darkname}` : 'market-top3-tab'} onClick={() => { setTopbtn1(2); setPage(1) }}><span>{t('market_2')}</span></div>
                        </div>
                    </div>
                    {
                        topbtn1 === 1
                            ?
                            <BinanceSpot BS_filterSymbol={BS_filterSymbol} page={page} setPage={setPage} maxFirstPage={maxFirstPage} SelectType={SelectType} darkname={darkname} spinner={spinner} />
                            :
                            <BinanceFutures BF_filterSymbol={BF_filterSymbol} page={page} setPage={setPage} maxFirstPage={maxFirstPage} SelectType={SelectType} darkname={darkname} spinner={spinner} />
                    }
                </div>
            </center>
        </div>
    )
}


function BinanceFutures({ BF_filterSymbol, page, setPage, maxFirstPage, SelectType, darkname, spinner }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let dark = useSelector((state) => { return state.Darkmode.value });

    const { t } = useTranslation();
    const [name1, setName1] = useState(0);
    const [name2, setName2] = useState(0);
    const [name3, setName3] = useState(1);

    if (name1 === 1) {
        BF_filterSymbol.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));
    }
    if (name1 === 2) {
        BF_filterSymbol.sort((a, b) => Number(a.lastPrice) - Number(b.lastPrice));
    }
    if (name2 === 1) {
        BF_filterSymbol.sort((a, b) => {
            if (a.priceChangePercent < b.priceChangePercent) return 1;
            if (a.priceChangePercent > b.priceChangePercent) return -1;
        });
    }
    if (name2 === 2) {
        BF_filterSymbol.sort((a, b) => {
            if (a.priceChangePercent > b.priceChangePercent) return 1;
            if (a.priceChangePercent < b.priceChangePercent) return -1;
        });
    }
    if (name3 === 1) {
        BF_filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));

    }
    if (name3 === 2) {
        BF_filterSymbol.sort((a, b) => Number(a.volume) * Number(a.lastPrice) - Number(b.volume) * Number(b.lastPrice));
    }

    BF_filterSymbol.sort((a, b) => a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1)
    BF_filterData = BF_filterSymbol.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    return (
        <div className={`market-list ${darkname}`}>
            <div className={`market-list-table ${darkname}`}>
                <div className='thead'>
                    <div className='tr'>
                        <div className='th'><span>{t('market_3')}</span></div>
                        <div className='th display-none-800'>
                            <div className={name1 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'} onClick={() => { { setName1(name1 => name1 === 1 ? 2 : 1); setName2(0); setName3(0) } }}>
                                <span>{t('market_4')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name1 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name1 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                        <div className='th display-none-800'>
                            <div className={name2 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'} onClick={() => { { setName2(name2 => name2 === 1 ? 2 : 1); setName1(0); setName3(0) } }}>
                                <span>{t('market_5')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name2 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                        <div className='th display-none-1000'><span>{t('market_6')}</span></div>
                        <div className='th display-none-1000'><span>{t('market_7')}</span></div>
                        <div className='th display-none-800'>
                            <div className={name3 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'} onClick={() => { { setName3(name3 => name3 === 1 ? 2 : 1); setName1(0); setName2(0) } }}>
                                <span>{t('market_8')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name3 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name3 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                        <div className='th display-none-800'><span>{t('market_9')}</span></div>
                        <div className='th display-none-1200'>
                            <div className={name2 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'}
                                style={{ paddingRight: "12px" }}
                                onClick={() => { { setName2(name2 => name2 === 1 ? 2 : 1); setName1(0); setName3(0) } }}>
                                <span>{t('market_10')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name2 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`market-list-tbody tbody ${darkname}`}>
                    {
                        spinner
                            ?
                            <div className='market-spinner'>
                                <SyncLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            <Fragment>
                                {BF_filterData.map(function (a, i) {
                                    return (
                                        <BinanceFuturesItem key={i} item={a} SelectType={SelectType} darkname={darkname} />
                                    )
                                })}
                                {
                                    API_Address == "dev"
                                        ?
                                        Type == "binance"
                                            ?
                                            <div className={`img-bg ${darkname}`}><img src={dark ? '/img/binance_dark.png' : '/img/binance.png'} style={{ maxWidth: "500px", width: "50%" }} /></div>
                                            :
                                            Type == "okx"
                                                ?
                                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/okx_logo_dark.png' : '/img/okx_logo.png'} style={{ maxWidth: "250px", width: "50%" }} /></div>
                                                :
                                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/bybit_logo_dark.png' : '/img/bybit.png'} style={{ maxWidth: "240px", width: "50%" }} /></div>
                                        :
                                        ""
                                }
                            </Fragment>
                    }
                </div>
            </div>
            <Page initialData={BF_filterSymbol} page={page} setPage={setPage} maxFirstPage={maxFirstPage} loca={"market"} />
        </div>
    )
}

function BinanceFuturesItem({ item, SelectType, darkname }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const [message, setMessage] = useState([])
    let dispatch = useDispatch();

    let imgcheck = true
    if (IMG && !IMG.includes(item.ccy)) {
        imgcheck = false
    }

    const Favorite = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/bookmark", {
                exchange: SelectType === 0 ? 'okx' : SelectType === 1 ? 'binance' : "bybit",
                type: 'futures',
                coin: item.ccy
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code === 1000) {
                        setMessage({ type: "binance", code: response?.data?.code, msg: "Favorited successfully" })
                        if (SelectType === 0) {
                            dispatch(change_OKX_F_Favorite(item.ccy))
                        }
                        else if (SelectType === 1) {
                            dispatch(change_Binance_F_Favorite(item.ccy))
                        }
                        else {
                            dispatch(change_Bybit_F_Favorite(item.ccy))
                        }
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 25, "msg": error }))
                })
        }
    }

    let futuresCheck = 0
    let futuresPreDataCheck = true

    for (let i = 0; i < futuresPreData.length; i++) {
        if (item.ccy == futuresPreData[i].ccy) {
            if (futuresPreData[i].price != item.lastPrice) {
                if (item.lastPrice > futuresPreData[i].price) futuresCheck = 1
                else if (item.lastPrice < futuresPreData[i].price) futuresCheck = 2

                futuresPreData[i].price = item.lastPrice
            }
            futuresPreDataCheck = false
        }
    }
    if (futuresPreDataCheck) futuresPreData.push({ ccy: item.ccy, price: item.lastPrice })

    return (
        <>
            {
                <>
                    <div className="display-none-800 tr">
                        {
                            API_Address == "dev"
                                ?
                                <Modal message={message} />
                                :
                                ""
                        }
                        <div className='market-list-tbody1-ico link' onClick={() => Favorite()}>
                            {
                                item.favorite
                                    ?
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} className='color' />
                                    :
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
                            }
                        </div>
                        <Link to={`/futures/${Type}/${item.symbol}`} className="tr" style={{ textDecoration: "none" }} >
                            <div className='td'>
                                <div className={`market-list-tbody1 ${darkname}`}>
                                    <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} className={`market-list-tbody1-img ${darkname}`}></img>
                                    <div>
                                        <span>{item.ccy}</span>
                                        <span style={{ color: "#81858c" }}>USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className='td display-none-800'>
                                <span className={futuresCheck === 0 ? `` : `market-item-bg ${futuresCheck === 1 ? "up" : "down"} ${darkname}`}>{changeNum(Number(item.lastPrice).toFixed(item.point))}</span>
                            </div>
                            {
                                item.priceChangePercent >= 0
                                    ?
                                    <div className="td green display-none-800"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                    :
                                    <div className="td red display-none-800"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                            }
                            <div className='td display-none-1000'><span>{changeNum(Number(item.highPrice).toFixed(item.point))}</span></div>
                            <div className='td display-none-1000'><span>{changeNum(Number(item.lowPrice).toFixed(item.point))}</span></div>
                            <div className='td display-none-800'><span>{changeNum((Number(item.lastPrice) * Number(item.volume) / 1000000).toFixed(2))}</span><span>M</span></div>
                            <div className='td display-none-800'>
                                <Link to={`/futures/${Type}/${item.symbol}`} style={{ textDecoration: "none" }} >
                                    <div className='market-list-tbody-trade'><FontAwesomeIcon icon={faChartSimple} /></div>
                                </Link>
                            </div>
                        </Link>
                    </div>
                    <div className={item.check ? `display-market-block tr market-item-bg ${darkname}` : "display-market-block tr"}>
                        <div className='market-list-tbody1-ico link' onClick={() => Favorite()}>
                            {
                                item.favorite
                                    ?
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} className='color' />
                                    :
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
                            }
                        </div>
                        <Link to={`/futures/${Type}/${item.symbol}`} className="tr" style={{ textDecoration: "none" }} >
                            <div className='td' style={{ position: "relative" }}>
                                <div className={`market-list-tbody1 ${darkname}`}>
                                    <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} className={`market-list-tbody1-img ${darkname}`}></img>
                                    <div style={{ fontSize: "16px" }}>
                                        <span>{item.ccy}</span>
                                        <span style={{ color: "#81858c" }}>/USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className='td display-none-right' style={{ paddingRight: "24px" }}>
                                <div>{changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                                {
                                    item.priceChangePercent >= 0
                                        ?
                                        <div className="green"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                        :
                                        <div className="red"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                }
                            </div>
                        </Link>
                    </div>
                </>
            }
        </>
    )
}

function BinanceSpot({ BS_filterSymbol, page, setPage, maxFirstPage, SelectType, darkname, spinner }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let dark = useSelector((state) => { return state.Darkmode.value });

    const { t } = useTranslation();
    const [name1, setName1] = useState(0);
    const [name2, setName2] = useState(0);
    const [name3, setName3] = useState(1);

    if (name1 === 1) {
        BS_filterSymbol.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));
    }
    else if (name1 === 2) {
        BS_filterSymbol.sort((a, b) => Number(a.lastPrice) - Number(b.lastPrice));
    }
    if (name2 === 1) {
        BS_filterSymbol.sort((a, b) => {
            if (a.priceChangePercent < b.priceChangePercent) return 1;
            if (a.priceChangePercent > b.priceChangePercent) return -1;
        });
    }
    else if (name2 === 2) {
        BS_filterSymbol.sort((a, b) => {
            if (a.priceChangePercent > b.priceChangePercent) return 1;
            if (a.priceChangePercent < b.priceChangePercent) return -1;
        });
    }
    if (name3 === 1) {
        BS_filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
    }
    else if (name3 === 2) {
        BS_filterSymbol.sort((a, b) => Number(a.volume) * Number(a.lastPrice) - Number(b.volume) * Number(b.lastPrice));
    }

    BS_filterSymbol.sort((a, b) => a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1)
    BS_filterData = BS_filterSymbol.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    return (
        <div className={`market-list ${darkname}`}>
            <div className={`market-list-table ${darkname}`}>
                <div className='thead'>
                    <div className='tr'>
                        <div className='th'><span>{t('market_3')}</span></div>
                        <div className='th display-none-800'>
                            <div className={name1 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'} onClick={() => { { setName1(name1 => name1 === 1 ? 2 : 1); setName2(0); setName3(0) } }}>
                                <span>{t('market_4')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name1 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name1 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                        <div className='th display-none-800'>
                            <div className={name2 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'} onClick={() => { { setName2(name2 => name2 === 1 ? 2 : 1); setName1(0); setName3(0) } }}>
                                <span>{t('market_5')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name2 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                        <div className='th display-none-1000'><span>{t('market_6')}</span></div>
                        <div className='th display-none-1000'><span>{t('market_7')}</span></div>
                        <div className='th display-none-800'>
                            <div className={name3 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'} onClick={() => { { setName3(name3 => name3 === 1 ? 2 : 1); setName1(0); setName2(0) } }}>
                                <span>{t('market_8')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name3 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name3 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                        <div className='th display-none-800'><span>{t('market_9')}</span></div>
                        <div className='th display-none-1200'>
                            <div className={name2 === 0 ? 'market-list-thead-sort' : 'market-list-thead-sort orange'}
                                style={{ paddingRight: "12px" }}
                                onClick={() => { { setName2(name2 => name2 === 1 ? 2 : 1); setName1(0); setName3(0) } }}>
                                <span>{t('market_10')}</span>
                                <span><span className="index_sort-btn__eWsx8"><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}></path>
                                </svg><svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`${name2 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}></path>
                                    </svg></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`market-list-tbody tbody ${darkname}`} style={{ position: "relative" }}>
                    {
                        spinner
                            ?
                            <div className='market-spinner'>
                                <SyncLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                            </div>
                            :
                            <Fragment>
                                {BS_filterData.map((a, i) => (
                                    <BinanceSpotItem key={i} item={a} SelectType={SelectType} darkname={darkname} />
                                ))}
                                {
                                    API_Address == "dev"
                                        ?
                                        Type == "binance"
                                            ?
                                            <div className={`img-bg ${darkname}`}><img src={dark ? '/img/binance_dark.png' : '/img/binance.png'} style={{ maxWidth: "500px", width: "50%" }} /></div>
                                            :
                                            Type == "okx"
                                                ?
                                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/okx_logo_dark.png' : '/img/okx_logo.png'} style={{ maxWidth: "250px", width: "50%" }} /></div>
                                                :
                                                <div className={`img-bg ${darkname}`}><img src={dark ? '/img/bybit_logo_dark.png' : '/img/bybit.png'} style={{ maxWidth: "240px", width: "50%" }} /></div>
                                        :
                                        ""
                                }
                            </Fragment>
                    }
                </div>
            </div>
            <Page initialData={BS_filterSymbol} page={page} setPage={setPage} maxFirstPage={maxFirstPage} loca={"market"} />
        </div>
    )
}

function BinanceSpotItem({ item, SelectType, darkname }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const [message, setMessage] = useState([])

    let dispatch = useDispatch();

    let imgcheck = true
    if (IMG && !IMG.includes(item.ccy)) {
        imgcheck = false
    }

    const Favorite = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/bookmark", {
                exchange: SelectType === 0 ? 'okx' : SelectType === 1 ? 'binance' : 'bybit',
                type: 'spot',
                coin: item.ccy
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code === 1000) {
                        setMessage({ type: "binance", code: response?.data?.code, msg: "Favorited successfully" })
                        if (SelectType === 0) {
                            dispatch(change_OKX_S_Favorite(item.ccy))
                        }
                        else if (SelectType === 1) {
                            dispatch(change_Binance_S_Favorite(item.ccy))
                        }
                        else {
                            dispatch(change_Bybit_S_Favorite(item.ccy))
                        }
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 26, "msg": error }))
                })
        }
    }

    let spotCheck = 0
    let spotPreDataCheck = true

    for (let i = 0; i < spotPreData.length; i++) {
        if (item.ccy == spotPreData[i].ccy) {
            if (spotPreData[i].price != item.lastPrice) {
                if (item.lastPrice > spotPreData[i].price) spotCheck = 1
                else if (item.lastPrice < spotPreData[i].price) spotCheck = 2

                spotPreData[i].price = item.lastPrice
            }
            spotPreDataCheck = false
        }
    }
    if (spotPreDataCheck) spotPreData.push({ ccy: item.ccy, price: item.lastPrice })
    return (
        <>
            {
                <>
                    <div className="display-none-800 tr">
                        {
                            API_Address == "dev"
                                ?
                                <Modal message={message} />
                                :
                                ""
                        }
                        <div className='market-list-tbody1-ico link' onClick={() => Favorite()}>
                            {
                                item.favorite
                                    ?
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} className='color' />
                                    :
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
                            }
                        </div>
                        <Link to={`/spot/${Type}/${item.symbol}`} className="tr" style={{ textDecoration: "none" }} >
                            <div className='td'>
                                <div className={`market-list-tbody1 ${darkname}`}>
                                    <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} className={`market-list-tbody1-img ${darkname}`}></img>
                                    <div>
                                        <span>{item.ccy}</span>
                                        <span style={{ color: "#81858c" }}>/USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className='td display-none-800'>
                                <span className={spotCheck === 0 ? `` : `market-item-bg ${spotCheck === 1 ? "up" : "down"} ${darkname}`}>{changeNum(Number(item.lastPrice).toFixed(item.point))}</span>
                            </div>
                            {
                                item.priceChangePercent >= 0
                                    ?
                                    <div className="td green display-none-800"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                    :
                                    <div className="td red display-none-800"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                            }
                            <div className='td display-none-1000'><span>{changeNum(Number(item.highPrice).toFixed(item.point))}</span></div>
                            <div className='td display-none-1000'><span>{changeNum(Number(item.lowPrice).toFixed(item.point))}</span></div>
                            <div className='td display-none-800'><span>{changeNum((Number(item.lastPrice) * Number(item.volume) / 1000000).toFixed(2))}</span><span>M</span></div>
                            <div className='td display-none-800'>
                                <Link to={`/spot/${Type}/${item.symbol}`} style={{ textDecoration: "none" }} >
                                    <div className='market-list-tbody-trade'><FontAwesomeIcon icon={faChartSimple} /></div>
                                </Link>
                            </div>
                        </Link>
                    </div>
                    <div className={item.check ? `display-market-block tr market-item-bg ${darkname}` : "display-market-block tr"}>
                        <div className='market-list-tbody1-ico link' onClick={() => Favorite()}>
                            {
                                item.favorite
                                    ?
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} className='color' />
                                    :
                                    <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
                            }
                        </div>
                        <Link to={`/spot/${Type}/${item.symbol}`} className="tr" style={{ textDecoration: "none" }} >
                            <div className='td' style={{ position: "relative" }}>
                                <div className={`market-list-tbody1 ${darkname}`}>
                                    <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} className={`market-list-tbody1-img ${darkname}`}></img>
                                    <div style={{ fontSize: "16px" }}>
                                        <span>{item.ccy}</span>
                                        <span style={{ color: "#81858c" }}>/USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className='td display-none-right' style={{ paddingRight: "24px" }}>
                                <div>{changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                                {
                                    item.priceChangePercent >= 0
                                        ?
                                        <div className="green"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                        :
                                        <div className="red"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                }
                            </div>
                        </Link>
                    </div>
                </>
            }
        </>
    )
}

export default Market;