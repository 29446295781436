import '../../css/futures.css'
import '../../css/modal.css'

import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretDown, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { check_Error } from '../../../store';
import { RoundDown, changeTime, padZero, UTCTimestamp } from "../../common/function"
import { ClipLoader } from "react-spinners";

import { Multi, Alarm, OpenOrder, OrderHistory, TradeHistory, Position } from '../../common/C_futures/W_goex8.js';

function GoexBnF8({ tab5, setTab5, symbol, USDTSymbolF, setPositionAmount, positionData, setTradeVolume, tradeVolume, totalexinfo, currentPrice, orderReload, openorder }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const [positionbtn, setPositionbtn] = useState(true)
    const [message, setMessage] = useState([])
    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    const [openorderList, setOpenorderList] = useState([])
    const [positionList, setPositionList] = useState([])
    
    useEffect(() => {
        let arr = []
        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                let sym = positionData[i].symbol.split('USDT')
                let myData1 = USDTSymbolF.filter((item) => { return item.symbol === positionData[i].symbol })
                let myData = myData1[0]
                let minSize1 = totalexinfo.filter((item) => { return item.symbol === positionData[i].symbol })
                let minSize = minSize1[0]?.filters[2]?.minQty ?? 0

                console.log(positionData)
                arr.push({
                    entryPrice: myData?.point ? Number(positionData[i].entryPrice).toFixed(myData.point) : Number(positionData[i].entryPrice),
                    value: positionData[i].notional,
                    ccy: sym[0],
                    symbol: positionData[i].symbol,
                    side: Number(positionData[i].positionAmt) >= 0 ? 'Buy' : 'Sell',
                    sz: positionData[i].positionAmt,
                    mark: myData?.point ? Number(positionData[i].markPrice).toFixed(myData.point) : Number(positionData[i].markPrice),
                    mode: positionData[i].marginType == "cross" ? "Cross" : "Isolated",
                    liq: myData?.point ? Number(positionData[i].liquidationPrice).toFixed(myData.point) : Number(positionData[i].liquidationPrice),
                    pnl: positionData[i].unRealizedProfit,
                    leverage: positionData[i].leverage,
                    minSize: minSize
                })
            }
        }
        setPositionList(arr)
    }, [positionData])

    useEffect(() => {
        if (openorder) {
            let arr = []
            for (let i = 0; i < openorder.length; i++) {
                let sym = openorder[i].symbol.split('USDT')
                arr.push({
                    ccy: sym,
                    symbol: openorder[i].symbol,
                    orderId: openorder[i].orderId,
                    type: openorder[i].type,
                    side: openorder[i].side,
                    px: openorder[i].price,
                    sz: openorder[i].origQty
                })
            }
            setOpenorderList(arr)
        }
    }, [openorder])

    const CancelAll = () => {
        let list = []

        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i].symbol
                if (s == symbol && !list.includes(s)) {
                    list.push(s)
                }
            }
        }

        if (list && list.length > 0) FuturesAllOpenOrderCancel(list)
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/all-order/cancel", {
                symbol: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 77, "msg": error }))
            })
        }
    }

    const FuturesOpenOrderCancel = async (item) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            setOpenorder_cancel(false)
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bn/futures/order/cancel", {
                orderId: item.orderId,
                symbol: item.symbol
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (response?.data?.code >= 0) {
                        setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: response?.data?.code, msg: t('message_34') })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 79, "msg": error }))
                })
        }
    }

    const FuturesPositionClose = async (p, z, m) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order', {
                symbol: p.symbol,
                side: Number(p.sz) >= 0 ? "SELL" : "BUY",
                type: 'MARKET',
                quantity: z === 0 ? Number(RoundDown(Math.abs(p.sz) / 2, m)) > 0 ? Number(RoundDown(Math.abs(p.sz) / 2, m)) : Math.abs(p.sz) : Math.abs(p.sz),
                price: "",
                timeInForce: "",
                reduceOnly: true,
                stopPrice: "",
                closePosition: "true"
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }
            ).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_14')}` })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 78, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-section8 ${darkname}`}>
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('futures_79')}</div>
                    <div className={tab5 === 1 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.symbol === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span></div>
                    <div className={tab5 === 3 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(3)}>{t('futures_45')}</div>
                    <div className={tab5 === 4 ? `futures-section8-top-1-1 active ${darkname}` : 'futures-section8-top-1-1'} onClick={() => setTab5(4)}>{t('futures_46')}</div>
                    <div className={tab5 === 5 ? `futures-section8-top-1-1 display-none-1000 active ${darkname}` : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(5)}>{t('futures_82')}</div>
                    {/* <div className={tab5 === 4 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(4)} style={{ color: "#F5AC1D" }}>{t('futures_83')}</div> */}
                    <div className='futures-section8-top-1-2 display-none-1000'>
                        <input type='checkbox' disabled={true} />
                    </div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className={`futures-section8-top-1-3 ${darkname}`} onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className={`futures-section8-top-1-3 ${darkname}`}><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                <FontAwesomeIcon icon={positionbtn ? faSquareCaretDown : faSquareCaretUp} className='display-none-700-block' style={{ color: "gray", fontSize: "18px", paddingRight: "5px" }} onClick={() => setPositionbtn(btn => !btn)} />
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Position setPositionAmount={setPositionAmount} positionList={positionList} symbol={symbol} currentPrice={currentPrice} FuturesPositionClose={FuturesPositionClose} exchange={"binance"} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder openorder={openorderList} symbol={symbol} all={false} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                            :
                            tab5 === 2
                                ?
                                <OpenOrder openorder={openorderList} symbol={symbol} all={true} darkname={darkname} delay={delay} openorder_cancel={openorder_cancel} FuturesOpenOrderCancel={FuturesOpenOrderCancel} />
                                :
                                tab5 === 3
                                    ?
                                    <FuturesOrderHistory orderReload={orderReload} />
                                    :
                                    tab5 === 4
                                        ?
                                        <FuturesTradeHistory orderReload={orderReload} />
                                        :
                                        <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                    // :
                    // <Multi />
                }
            </div>
        </div>
    )
}

let o_id = 0
function FuturesOrderHistory({ orderReload }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderdata, setOrderData] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/history`, {
                params: {
                    reqId: o_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && o_id - 1 === Number(reqId)) {
                    setOrderlist(tmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 72, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].symbol.split('USDT')

                const date = UTCTimestamp(new Date(orderlist[i].transaction_time));
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                summary.push({
                    symbol: orderlist[i].symbol,
                    ccy: arr[0],
                    volume: Number(orderlist[i].original_price) * Number(orderlist[i].original_quantity),
                    type: orderlist[i].order_type,
                    time: formattedDate,
                    side: orderlist[i].side == "BUY" ? "Buy" : "Sell",
                    price: orderlist[i].original_price,
                    size: orderlist[i].original_quantity,
                    status: orderlist[i].order_status
                })
            }
        }
        setOrderData(summary)
    }, [orderlist])

    return (
        <OrderHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderdata} spinner={spinner} />
    )
}

let t_id = 0
function FuturesTradeHistory({ orderReload }) {
    let dispatch = useDispatch();

    const [orderlist, setOrderlist] = useState([])
    const [orderlistSum, setOrderlistSum] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [personal_fee, setPersonal_fee] = useState(0.25)
    const [spinner, setSpinner] = useState(true)

    useEffect(() => {
        setSpinner(true)
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1, orderReload])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/history`, {
                params: {
                    reqId: t_id++,
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86399999
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let reqId = response?.data?.reqId
                let tmp = response?.data?.data?.data
                if (tmp && t_id - 1 === Number(reqId)) {
                    let arrTmp = tmp.filter(i => i.execution_type == "TRADE")
                    setOrderlist(arrTmp)
                    setSpinner(false)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 73, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []

        if (orderlist) {
            for (let i = 0; i < orderlist.length; i++) {
                let arr = orderlist[i].symbol.split('USDT')

                const date = UTCTimestamp(new Date(orderlist[i].transaction_time));
                const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

                summary.push({
                    symbol: orderlist[i].symbol,
                    ccy: arr[0],
                    volume: Number(orderlist[i].last_filled_price) * Number(orderlist[i].order_last_filled_quantity),
                    pnl: Number(orderlist[i].realized_profit),
                    fee: orderlist[i].commission ? Number(orderlist[i].commission) : 0,
                    time: formattedDate,
                    reward: Number(orderlist[i].reward),
                    side: orderlist[i].side == "BUY" ? "Buy" : "Sell",
                    price: orderlist[i].last_filled_price,
                    size: orderlist[i].order_last_filled_quantity,
                })
            }
        }
        setOrderlistSum(summary)
    }, [orderlist])

    return (
        <TradeHistory setSelectedDate={setSelectedDate} selectedDate={selectedDate} setSelectedDate1={setSelectedDate1} selectedDate1={selectedDate1} orderdata={orderlistSum} spinner={spinner} />
    )
}

export default GoexBnF8;