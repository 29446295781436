import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import { useTranslation } from "react-i18next";

import { C_QuickWithdrawal } from '../../common/C_wallet/W_wallet';

let coinListReci = [];
let coinList = [];

function OKX_QuickWithdrawal({ reload, setReload }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    let Bybit_Mode = useSelector((state) => { return state.Bybit_Mode.value })
    let OKX_Mode = useSelector((state) => { return state.OKX_Mode.value })

    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [amount, setAmount] = useState("")
    const [selectedSend, setSelectedSend] = useState("OKX");
    const [selectedReci, setSelectedReci] = useState("Binance");

    const [Bn_selectCoin, SetBn_selectCoin] = useState("")
    const [okx_selectCoin, SetOKX_SelectCoin] = useState("")
    const [By_selectCoin, SetBy_SelectCoin] = useState("")
    const [quickHistory, setQuickHistory] = useState([])
    const [quickHistoryList, setQuickHistoryList] = useState([])

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])
    const [modal_message, setModal_Message] = useState([])
    const [modal_buyModal, setModal_BuyModal] = useState(false)

    const [code, setCode] = useState(['', '', '', '', '', '']); // 초기값으로 6개의 빈 문자열을 가진 배열 생성

    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    coinListReci = API_Address == "dev" ? ["Binance", "Bybit"] : OKX_Mode ? Bybit_Mode ? ["Binance", "Bybit", "OKX"] : ["Binance", "OKX"] : Bybit_Mode ? ["Binance", "Bybit"] : ["Binance"];
    coinList = API_Address == "dev" ? ["OKX"] : OKX_Mode ? Bybit_Mode ? ["Binance", "Bybit", "OKX"] : ["Binance", "OKX"] : Bybit_Mode ? ["Binance", "Bybit", "OKX"] : ["OKX"];

    useEffect(() => {
        setSelectedSend(coinList[0])
        setSelectedReci(coinListReci[0])
    }, [])

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // 다음 입력란으로 이동
            if (index < code.length - 1 && value) {
                const nextInput = e.target.parentNode.nextSibling.querySelector('input');
                if (nextInput) {
                    nextInput.focus();
                }
            }

            // 이전 입력란으로 이동
            if (index > 0 && !value) {
                const prevInput = e.target.parentNode.previousSibling.querySelector('input');
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    };

    const handleSelectSend = (event) => {
        setSelectedSend(event.target.value);
        let ListReci = coinListReci.filter((item) => item != event.target.value)
        setSelectedReci(ListReci[0]);
    };

    const handleSelectReci = (event) => {
        setSelectedReci(event.target.value);
    };

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            Bn_DepositLink(token)
            OKX_DepositLink(token)
            By_DepositLink(token)
        }
    }, [reload])

    useEffect(() => {
        QuickWithdrawalHistory(0)
    }, [reload])

    const Bn_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/bn/address`, {
            params: {
                coin: "USDT",
                network: "TRX",
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp) {
                SetBn_selectCoin(tmp.address)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 140, "msg": error }))
        })
    }

    const OKX_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-address`, {
            params: {
                ccy: "USDT"
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp[1]) {
                SetOKX_SelectCoin(tmp[6].addr)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 141, "msg": error }))
        })
    }

    const By_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/deposit/address', {
            params: {
                coin: "USDT",
                chainType: "TRX"
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                SetBy_SelectCoin(tmp?.data?.result?.chains?.addressDeposit ?? [])
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 142, "msg": error }))
        })
    }

    const QuickWithdrawalHistory = async (n) => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/cm/quick-withdrawal', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp?.code >= 1000) {
                    if (n === 1) setModal_Message({ type: "binance", code: tmp?.code, msg: "Refresh successful" })
                    setQuickHistory(tmp.data)
                }
                else {
                    setModal_Message({ type: "binance", code: tmp?.code, msg: "Refresh Failed" })
                }
                if (API_Address == "rf" && n == 1) setModal_BuyModal(true)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 143, "msg": error }))
            })
        }
    }

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setSpinner(true)

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                params: { otp: code.join('') },
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    onQuickWithdraw()
                }
                else {
                    setModal_Message({ type: "binance", code: response?.data?.code, msg: t('message_42') })
                    if (API_Address == "rf") setModal_BuyModal(true)
                    setW_confirm(false)
                    setSpinner(false);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 144, "msg": error }))
            })
        }
    }


    const onQuickWithdraw = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/wallet/cm/quick-withdrawal`, {
                amount: amount,
                fromEx: selectedSend == "Binance" ? "bn" : selectedSend == "OKX" ? "okx" : "bybit",
                toEx: selectedReci == "Binance" ? "bn" : selectedReci == "OKX" ? "okx" : "bybit",
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setTimeout(() => {
                        setSpinner(false);
                        setReload(e => !e)
                        setAmount("")
                        setCode(['', '', '', '', '', ''])
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: `${t('message_20')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: response?.data?.msg })
                    }, 1000);
                }
                setW_confirm(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 145, "msg": error }))
            })
        }
    }

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            setW_confirm(false)
            CheckGoogleOTP()
        }
    }, [w_confirm])

    useEffect(()=>{
        if(quickHistory){
            let arr = []
            for(let i=0;i<quickHistory.length;i++){
                arr.push({
                    time: quickHistory[i].regist_date,
                    exchange: quickHistory[i].exchange,
                    txId: quickHistory[i].txn_id,
                    amount: quickHistory[i].amount,
                    coin: quickHistory[i].coin,
                    network: quickHistory[i].network,
                    status: quickHistory[i].status,
                })
            }
            setQuickHistoryList(arr)
        }
    },[quickHistory])

    return (
        <C_QuickWithdrawal amount={amount} setAmount={setAmount} onChangeAmount={onChangeAmount} selectedSend={selectedSend} handleSelectSend={handleSelectSend} selectedReci={selectedReci} handleSelectReci={handleSelectReci} Bn_selectCoin={Bn_selectCoin} okx_selectCoin={okx_selectCoin} By_selectCoin={By_selectCoin} quickHistory={quickHistoryList} spinner={spinner} message={message} modal_message={modal_message} modal_buyModal={modal_buyModal} setModal_BuyModal={setModal_BuyModal} code={code} handleInputChange={handleInputChange} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} coinListReci={coinListReci} coinList={coinList} QuickWithdrawalHistory={QuickWithdrawalHistory} />
    )
}

export default OKX_QuickWithdrawal;