import { Link, useNavigate } from 'react-router-dom';
import '../../css/newmain.css'
import IMG from "../../img"
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, checkSelectType, check_Error } from '../../../store';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp, useCounter } from "../function"

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

let topTrade5 = []
let BinanceTotalTrade = 0;
let OKXTotalTrade = 0;
let BybitTotalTrade = 0;

let total_position = 0
let lang_arr = ""

function NewMain() {
    const { t } = useTranslation();
    let login = useSelector((state) => { return state.login.value });
    let BinanceS = useSelector((state) => { return state.Binance_coinS });
    let BinanceF = useSelector((state) => { return state.Binance_coinF });
    let OKXF = useSelector((state) => { return state.OKX_coinF });
    let BybitF = useSelector((state) => { return state.Bybit_coinF });
    let Languages = useSelector((state) => { return state.Languages.value })

    let Binance_wallet_spot = useSelector((state) => { return state.Binance_wallet_spot });
    let Binance_wallet_futures = useSelector((state) => { return state.Binance_wallet_futures });
    let OKX_wallet = useSelector((state) => { return state.OKX_wallet });
    let Bybit_wallet = useSelector((state) => { return state.Bybit_wallet });
    let Bybit_Mode = useSelector((state) => { return state.Bybit_Mode.value })
    let API_Address = useSelector((state) => { return state.API_Address.value })

    if (Languages) {
        lang_arr = Languages.toLowerCase()
        if (lang_arr == "vi-vn") {
            lang_arr = "en-us"
        }
    }

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const btnRef0 = useRef(null)
    const btnRef1 = useRef(null)
    const btnRef2 = useRef(null)
    const btnRef3 = useRef(null)
    // const btnRef4 = useRef(null)

    const [text, setText] = useState("")
    const [btn, setBtn] = useState([false, false, false, false])
    const [divHeight, setDivHeight] = useState([0, 0, 0, 0])
    const [sparkle, setSparkle] = useState(false)
    const [total_sum, setTotal_sum] = useState(0)
    const [Bn_spot_balance, setBn_spot_balance] = useState([])
    const [event, setEvent] = useState(true)

    const onChangeText = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            B_SpotBalance(token)
        }
    }, [Binance_wallet_spot])

    useEffect(() => {
        let sub_total_position = 0
        if (Binance_wallet_futures && Binance_wallet_futures[0]) {
            sub_total_position += Number(Binance_wallet_futures[0].balance)
        }

        if (OKX_wallet && OKX_wallet.length > 0) {
            for (let i = 0; i < OKX_wallet.length; i++) {
                let t = OKX_wallet[i]
                sub_total_position += Number(t.eqUsd)
            }
        }

        if (Bn_spot_balance) {
            let btc_sum = 0
            let btc_filter = BinanceS.filter((item) => item.ccy == "BTC")
            if (btc_filter && btc_filter[0]) {
                for (let i = 0; i < Bn_spot_balance.length; i++) {
                    btc_sum += Number(Bn_spot_balance[i].btcValuation)
                }
                sub_total_position += btc_sum * Number(btc_filter[0].lastPrice)
            }
        }

        if (Bybit_wallet && Bybit_Mode) {
            for (let i = 0; i < Bybit_wallet.length; i++) {
                let t = Bybit_wallet[i]
                sub_total_position += Number(t.usdValue)
            }
        }
        total_position = sub_total_position
    }, [Binance_wallet_futures, OKX_wallet, Bn_spot_balance, Bybit_wallet])

    const B_SpotBalance = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/bn/spot/info', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setBn_spot_balance(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 98, "msg": error }))
        })
    }

    const handleButtonClick = (e) => {
        setBtn(prevState => {
            const newState = [...prevState];
            for (let i = 0; i < newState.length; i++) {
                if (i === e && !newState[i]) newState[i] = true
                else newState[i] = false
            }
            return newState;
        });
    };

    useEffect(() => {
        if (btnRef0.current && btnRef1.current && btnRef2.current && btnRef3.current) {
            const height0 = btnRef0.current.clientHeight;
            const height1 = btnRef1.current.clientHeight;
            const height2 = btnRef2.current.clientHeight;
            const height3 = btnRef3.current.clientHeight;
            // const height4 = btnRef4.current.clientHeight;

            setDivHeight([height0, height1, height2, height3])
        }
    }, []);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    let BinanceF_Sort = BinanceF ? [...BinanceF] : [];
    let OKXF_Sort = OKXF ? [...OKXF] : [];
    let BybitF_Sort = BybitF ? [...BybitF] : [];

    if (BinanceF_Sort?.length > 0 && OKXF_Sort?.length > 0 && BybitF_Sort?.length > 0) {
        BinanceF_Sort.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
        OKXF_Sort.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
        BybitF_Sort.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));

        const topBinanceF = BinanceF_Sort.slice(0, 5);
        const topOKXF = OKXF_Sort.slice(0, 5);
        const topBybit = Bybit_Mode ? BybitF_Sort.slice(0, 5) : [];

        let topTrade = [...topBinanceF, ...topOKXF, ...topBybit];
        topTrade.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice))

        topTrade5 = topTrade.slice(0, 5);
    }

    if (BinanceF) {
        let total = 0;
        for (let i = 0; i < BinanceF.length; i++) {
            total += Number(BinanceF[i].volume) * Number(BinanceF[i].lastPrice)
        }
        BinanceTotalTrade = total
    }
    if (OKXF) {
        let total = 0;
        for (let i = 0; i < OKXF.length; i++) {
            total += Number(OKXF[i].volume) * Number(OKXF[i].lastPrice)
        }
        OKXTotalTrade = total
    }
    if (BybitF && Bybit_Mode) {
        let total = 0;
        for (let i = 0; i < BybitF.length; i++) {
            total += Number(BybitF[i].volume) * Number(BybitF[i].lastPrice)
        }
        BybitTotalTrade = total
    }

    const onRegister = () => {
        navigate('/regist', {
            state: {
                id: text,
                type: isNumericString(text) ? 1 : 0
            }
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onRegister()
        }
    };

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            First_PositionData(token)
        }
    }, [])

    const First_PositionData = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/cm/snapshot`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setTotal_sum(tmp.total)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 99, "msg": error }))
        })
    }

    useEffect(() => {
        if (sparkle) {
            setTimeout(() => {
                setSparkle(false)
            }, 300)
        }
        else {
            setTimeout(() => {
                setSparkle(true)
            }, 300)
        }
    }, [sparkle])

    useEffect(() => {
        document.title = "NAMUBIT│One-Stop Trading Platform - Crypto Trading Universe";
    }, []);

    return (
        <main className="newmain">
            {/* {
                API_Address == "rf" && event
                    ?
                    <Event_Modal setEvent={setEvent} lang_arr={lang_arr} />
                    :
                    ""
            } */}
            <div className="ay_visual">
                <div className="container">
                    <div className="ay_visual_wrap">
                        <div className="ay_w_100 ay_grid_48 ay_visual_desc">
                            <div className="ay_w_100 ay_grid_24">
                                <div className="ay_grid_12">
                                    <div className="ay_text_13">
                                        <span title='main_0'>{t('main_0')}</span>
                                    </div>
                                    <div className="ay_text_25 ay_bold_7 ay_line_12 letter_spacing_1">
                                        {t('main_1')}<br />{t('main_2')}
                                    </div>
                                </div>
                                <div className="">
                                    {t('main_3')}
                                </div>
                            </div>
                            {
                                login
                                    ?
                                    <div className="ay_w_100 ay_grid_24">
                                        <div className="ay_w_100 ay_grid_12">
                                            <div className="ay_flex_6">
                                                <div>💰</div>
                                                <div>{t('main_4')}</div>
                                            </div>
                                            <div className="ay_w_100 ay_flex_6">
                                                <div className="ay_text_15 ay_bold_5">
                                                    {changeNum(total_position.toFixed(3))}
                                                </div>
                                                <div className="ay_badge unit">
                                                    usdt
                                                </div>
                                            </div>
                                            <div className="ay_flex_6 ay_point0">
                                                <div>{t('main_5')}</div>
                                                <div className={`${total_position - total_sum >= 0 ? "ay_green" : "ay_red"}`}>{total_position - total_sum > 0 ? "+" : ""}{changeNum((total_position - total_sum).toFixed(5))}</div>
                                            </div>
                                        </div>
                                        <button type="button" className="ay_flex_6 ay_start ay_bold_7 ay_line_13 text_left"
                                            onClick={() => window.open(`${lang_arr == "ko-kr"
                                                ?
                                                'https://namubit.zendesk.com/hc/ko-kr/articles/35684819777049'
                                                :
                                                lang_arr == "ja-jp"
                                                    ?
                                                    'https://namubit.zendesk.com/hc/ja-jp/articles/35684819777049'
                                                    : 'https://namubit.zendesk.com/hc/en-us/articles/35684819777049'}`)}
                                        >
                                            <div>🎁</div>
                                            <div style={{ color: sparkle ? "#5381e3" : "" }}>{t('main_6')}</div>
                                        </button >
                                    </div >
                                    :
                                    <div className="ay_w_100 ay_grid_12">
                                        <div className="ay_input_text">
                                            <input type="text" className="pd_12" placeholder={`${t('main_7')}`} onKeyDown={handleKeyPress} value={text} onChange={onChangeText} />
                                            <input type="button" className="ay_input_text_btn" value={t('main_7-1')} onClick={() => onRegister()} />
                                        </div>
                                        <button type="button" className="ay_flex_6 ay_start ay_bold_7 ay_line_13 text_left"
                                            onClick={() => window.open(`${lang_arr == "ko-kr"
                                                ?
                                                'https://namubit.zendesk.com/hc/ko-kr/articles/35684819777049'
                                                :
                                                lang_arr == "ja-jp"
                                                    ?
                                                    'https://namubit.zendesk.com/hc/ja-jp/articles/35684819777049'
                                                    : 'https://namubit.zendesk.com/hc/en-us/articles/35684819777049'}`)}
                                        >
                                            {/* <button type="button" className="ay_flex_6 ay_start ay_bold_7 ay_line_13 text_left" onClick={() => window.open(`https://event.rf-rev.com/debugging-event-${lang_arr && lang_arr[0] ? lang_arr[0] : 'en'}`)}> */}
                                            <div>🎁</div>
                                            <div style={{ color: sparkle ? "#5381e3" : "" }}>{t('main_6')}</div>
                                        </button>
                                    </div>
                            }
                        </div >
                        <div className="ay_visual_market">
                            <div className="ay_w_100 ay_grid_24">
                                <div className="ay_text_13">
                                    {t('main_8')}
                                </div>
                                <div className="ay_table">
                                    {
                                        topTrade5.map(function (a, i) {
                                            return (
                                                <TopTradeMarkets key={i} i={i} item={a} />
                                            )
                                        })
                                    }
                                </div>
                                <Link to={`/market`}>
                                    <button type="button" className="ay_flex_6 text_basic">
                                        <div className='text_basic'>{t('main_9')}</div>
                                        <svg viewBox="0 0 341.45 245.55" className="ay_icon_13">
                                            <path d="m303.34,112.07h-10.29s-67.25,0-67.25,0c-70.57,0-141.12,0-211.69.02h-.2c-2.2,0-4.27,0-5.96.44-4.9,1.27-8.44,6.33-7.88,11.27.64,5.71,4.8,9.74,10.35,10.04.93.05,1.91.06,2.75.06h1.85s288.33,0,288.33,0l-8.02,8.44c-.56.59-1.05,1.11-1.48,1.57-.85.91-1.51,1.61-2.2,2.29l-16.86,16.87c-20.49,20.5-41.67,41.71-62.55,62.53-4.01,4-5.18,7.58-3.93,11.99,1.56,5.5,6.4,7.97,10.5,7.97,2.48,0,4.85-.84,6.84-2.44.72-.57,1.45-1.31,2.22-2.1l.62-.62c35.9-35.91,71.8-71.82,107.7-107.73,6.96-6.97,7.01-12.35.15-19.21l-31-31.01c-26.01-26.01-52.02-52.03-78.01-78.07C224.35,1.39,221.57,0,218.57,0c-1.02,0-2.11.16-3.24.49-3.56,1.02-6.25,3.63-7.37,7.18-1.12,3.57-.42,7.28,1.94,10.17.74.91,1.65,1.81,2.61,2.77l2.42,2.42"></path>
                                        </svg>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
            <div className="ay_banner3">
                <div className="container">
                    <div className="ay_banner3_wrap">
                        <div className="ay_benner3_desc">
                            <div className="ay_grid_24">
                                <div className="ay_text_23 ay_bold_7 ay_line_12">
                                    {t('main_10')}
                                </div>
                                <div className="ay_point0 ay_line_13">
                                    <span>{t('main_11')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="ay_benner3_content">
                            <Swiper
                                modules={[Pagination]}
                                spaceBetween={24}
                                slidesPerView={1.2}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1.2,
                                    },
                                    768: {
                                        slidesPerView: 2.2,
                                    },
                                    1024: {
                                        slidesPerView: 1.5,
                                    },
                                    1200: {
                                        slidesPerView: 2.2,
                                    }
                                }}
                                className="swiper ay_Swiper4 style_dot2"
                            >
                                <SwiperSlide>
                                    <div className="ay_w_100 ay_flex ay_center ay_benner3_img binance">
                                        <div className="ay_banner3_bedge">
                                            <div className="bedge binance ay_line_13">
                                                {t('main_12')}<br />
                                                {t('main_13')}
                                            </div>
                                        </div>
                                        <svg viewBox="0 0 633.06 126.61" className="ay_icon_100">
                                            <path className="binance-1"
                                                d="m38.73,53.2l24.59-24.58,24.6,24.6,14.3-14.31L63.32,0,24.42,38.9l14.31,14.3Z">
                                            </path>
                                            <path className="binance-1" d="m0,63.31l14.3-14.3,14.3,14.3-14.3,14.3L0,63.31Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m38.73,73.41l24.59,24.59,24.6-24.6,14.31,14.29h-.01s-38.9,38.92-38.9,38.92l-38.9-38.89-.02-.02,14.33-14.29Z">
                                            </path>
                                            <path className="binance-1" d="m98,63.31l14.3-14.3,14.3,14.3-14.3,14.3-14.3-14.3Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m77.83,63.3l-14.51-14.52-10.73,10.73-1.24,1.23-2.54,2.54-.02.02.02.03,14.51,14.5,14.51-14.52h.01s-.01-.01-.01-.01Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m148.93,30.68h31.12c7.72,0,13.56,2,17.52,6,3.02,2.99,4.67,7.08,4.59,11.32v.19c.03,1.72-.21,3.43-.71,5.08-.44,1.42-1.09,2.76-1.91,4-.77,1.17-1.7,2.22-2.76,3.12-1.04.89-2.18,1.67-3.39,2.3,3.5,1.22,6.65,3.28,9.17,6,2.23,2.53,3.34,6.03,3.34,10.5v.19c.06,2.79-.54,5.55-1.77,8.06-1.19,2.31-2.93,4.29-5.07,5.76-2.42,1.62-5.11,2.79-7.95,3.45-3.37.8-6.83,1.19-10.29,1.15h-31.89V30.68Zm28,27.14c2.69.13,5.37-.45,7.77-1.68,1.91-1.11,3.02-3.22,2.86-5.42v-.19c.1-2.02-.84-3.96-2.49-5.13-1.66-1.18-4.06-1.77-7.19-1.77h-14.56v14.19h13.61Zm3.93,27.05c2.68.15,5.35-.46,7.7-1.77,1.86-1.2,2.93-3.31,2.78-5.52v-.19c.07-2.09-.9-4.09-2.59-5.32-1.72-1.33-4.5-2-8.34-2h-17.09v14.8h17.54Z">
                                            </path>
                                            <path className="binance-1" d="m224.44,30.68h14.77v67.14h-14.77V30.68Z"></path>
                                            <path className="binance-1"
                                                d="m261.56,30.68h13.62l31.48,41.32V30.68h14.58v67.14h-12.57l-32.51-42.68v42.68h-14.6V30.68Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m365.96,30.2h13.6l28.77,67.62h-15.42l-6.14-15.06h-28.39l-6.14,15.06h-15.06l28.78-67.62Zm15.54,39.52l-8.94-21.78-8.9,21.78h17.84Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m424.3,30.68h13.62l31.46,41.32V30.68h14.58v67.14h-12.57l-32.51-42.68v42.68h-14.58V30.68Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m537.12,99c-4.71.04-9.37-.87-13.72-2.68-8.27-3.41-14.8-10.02-18.13-18.32-1.74-4.3-2.62-8.89-2.59-13.53v-.19c-.08-9.14,3.44-17.94,9.79-24.51,3.13-3.21,6.87-5.75,11-7.48,4.5-1.86,9.32-2.79,14.19-2.73,2.88-.02,5.75.24,8.58.77,2.42.46,4.79,1.16,7.06,2.11,2.06.87,4.03,1.96,5.85,3.26,1.78,1.27,3.45,2.68,5,4.22l-9.39,10.84c-2.4-2.21-5.09-4.09-8-5.57-2.86-1.37-5.99-2.05-9.16-2-5.33-.06-10.43,2.18-14,6.14-1.75,1.95-3.12,4.21-4.04,6.67-.96,2.59-1.45,5.34-1.43,8.11v.19c0,2.76.47,5.51,1.43,8.1.91,2.46,2.26,4.74,4,6.71,3.54,4.01,8.65,6.29,14,6.24,3.37.11,6.71-.62,9.73-2.11,2.88-1.57,5.54-3.5,7.91-5.76l9.4,9.5c-1.66,1.8-3.45,3.47-5.37,5-1.88,1.49-3.92,2.76-6.09,3.79-2.31,1.09-4.74,1.89-7.24,2.39-2.89.58-5.83.86-8.78.84Z">
                                            </path>
                                            <path className="binance-1"
                                                d="m582.03,30.68h50.53v13.14h-36v13.62h31.7v13.14h-31.65v14.1h36.45v13.14h-51l-.03-67.14Z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="ay_box pd24 bg_white">
                                        <div className="ay_w_100 ay_grid_24">
                                            <div className="ay_w_100 ay_grid_12">
                                                <div className="ay_flex_6 ay_center ay_text_13 ay_bold_7">
                                                    <div className="ay_point0">24H</div>
                                                    <div className="">{changeNum(useCounter(Math.floor(BinanceTotalTrade / 1000000)), 0)}M USDT</div>
                                                </div>
                                                <a href='https://www.binance.com/en/link' target='_blank' rel="noopener noreferrer" className='btn classic_op' style={{ textAlign: "center" }}>{t('main_14')}</a>
                                            </div>
                                            <div className="ay_text_8 ay_line_15 ay_point0 ay_banner3_desc">
                                                {t('main_15')}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="ay_w_100 ay_flex ay_center ay_benner3_img okx">
                                        <div className="ay_banner3_bedge">
                                            <div className="bedge okx ay_line_13">
                                                {t('main_12')}<br />
                                                {t('main_13')}
                                            </div>
                                        </div>
                                        <svg viewBox="0 0 446.25 125.25" className="ay_icon_100">
                                            <path id="path839" className="okx-1"
                                                d="m130.88,0H2.97c-.79,0-1.55.29-2.1.82-.56.52-.87,1.23-.87,1.97v119.68c0,.74.31,1.45.87,1.97.56.52,1.31.81,2.1.81h127.9c.79,0,1.55-.29,2.1-.81.56-.52.87-1.23.87-1.97V2.78c0-.74-.31-1.45-.87-1.97-.56-.52-1.31-.82-2.1-.82Zm-41.64,80.71c0,.74-.31,1.45-.87,1.97-.56.52-1.31.82-2.1.82h-38.67c-.79,0-1.55-.29-2.1-.82-.56-.52-.87-1.23-.87-1.97v-36.18c0-.74.31-1.45.87-1.97.56-.52,1.31-.82,2.1-.82h38.67c.79,0,1.55.29,2.1.82.56.52.87,1.23.87,1.97v36.18Z">
                                            </path>
                                            <path id="path841" className="okx-1"
                                                d="m398.63,41.75h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78h0Z">
                                            </path>
                                            <path id="path843" className="okx-1"
                                                d="m354.03,0h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.98-1.25,2.98-2.78V2.79c0-1.54-1.33-2.78-2.98-2.78Z">
                                            </path>
                                            <path id="path845" className="okx-1"
                                                d="m443.28,0h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78V2.79c0-1.54-1.33-2.78-2.97-2.78Z">
                                            </path>
                                            <path id="path847" className="okx-1"
                                                d="m354.03,83.5h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.98-1.25,2.98-2.78v-36.18c0-1.54-1.33-2.78-2.98-2.78Z">
                                            </path>
                                            <path id="path849" className="okx-1"
                                                d="m443.28,83.5h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78Z">
                                            </path>
                                            <path id="path851" className="okx-1"
                                                d="m287.05,0h-38.67c-1.64,0-2.98,1.25-2.98,2.78v36.18c0,1.54,1.33,2.78,2.98,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78V2.79c0-1.54-1.33-2.78-2.97-2.78Z">
                                            </path>
                                            <path id="path853" className="okx-1"
                                                d="m287.05,83.5h-38.67c-1.64,0-2.98,1.25-2.98,2.78v36.18c0,1.54,1.33,2.78,2.98,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78Z">
                                            </path>
                                            <path id="path855" className="okx-1"
                                                d="m245.39,44.5c0-.74-.31-1.45-.87-1.97-.56-.52-1.31-.82-2.1-.82h-41.64V2.78c0-.74-.31-1.45-.87-1.97-.56-.52-1.31-.82-2.1-.82h-38.67c-.79,0-1.55.29-2.1.82-.56.52-.87,1.23-.87,1.97v119.62c0,.74.31,1.45.87,1.97.56.52,1.31.82,2.1.82h38.67c.79,0,1.55-.29,2.1-.82s.87-1.23.87-1.97v-38.93h41.64c.79,0,1.55-.29,2.1-.82.56-.52.87-1.23.87-1.97v-36.18Z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="ay_box pd24 bg_white">
                                        <div className="ay_w_100 ay_grid_24">
                                            <div className="ay_w_100 ay_grid_12">
                                                <div className="ay_flex_6 ay_center ay_text_13 ay_bold_7">
                                                    <div className="ay_point0">24H</div>
                                                    <div className="">{changeNum(useCounter(Math.floor(OKXTotalTrade / 1000000)), 0)}M USDT</div>
                                                </div>
                                                <a href='https://www.okx.com/broker/home' target='_blank' rel="noopener noreferrer" className='btn classic_op' style={{ textAlign: "center" }}>{t('main_16')}</a>
                                            </div>
                                            <div className="ay_text_8 ay_line_15 ay_point0 ay_banner3_desc">
                                                {t('main_17')}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="ay_w_100 ay_flex ay_center ay_benner3_img bybit">
                                        <div className="ay_banner3_bedge">
                                            <div className="bedge bybit ay_line_13">
                                                {t('main_12')}<br />
                                                {t('main_13')}
                                            </div>
                                        </div>
                                        <svg viewBox="0 0 2498.8 833" className="ay_icon_100">
                                            <polygon className="bybit-1" points="1781 642.2 1781 0 1910.1 0 1910.1 642.2 1781 642.2"></polygon>
                                            <path className="bybit-1"
                                                d="m276.7,832.9H0V190.8h265.6c129,0,204.3,70.4,204.3,180.4,0,71.3-48.3,117.2-81.8,132.6,39.9,18,91,58.6,91,144.3,0,119.8-84.5,184.8-202.4,184.8h0Zm-21.3-530.2h-126.4v147.9h126.4c54.8,0,85.5-29.8,85.5-74s-30.7-73.9-85.5-73.9h0Zm8.3,260.6h-134.7v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4s-27.9-78.4-86.3-78.4Z">
                                            </path>
                                            <polygon className="bybit-1"
                                                points="872.8 569.5 872.8 832.9 744.6 832.9 744.6 569.5 545.9 190.8 686.2 190.8 809.6 449.6 931.3 190.8 1071.5 190.8 872.8 569.5">
                                            </polygon>
                                            <path className="bybit-1"
                                                d="m1437.4,832.9h-276.7V190.8h265.6c129,0,204.3,70.4,204.3,180.4,0,71.3-48.3,117.2-81.8,132.6,39.9,18,91,58.6,91,144.3,0,119.8-84.4,184.8-202.4,184.8h0Zm-21.3-530.2h-126.3v147.9h126.3c54.8,0,85.5-29.8,85.5-74-.1-44.2-30.8-73.9-85.5-73.9Zm8.3,260.6h-134.6v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4s-27.9-78.4-86.4-78.4h0Z">
                                            </path>
                                            <polygon className="bybit-1"
                                                points="2326.1 302.7 2326.1 833 2197 833 2197 302.7 2024.3 302.7 2024.3 190.8 2498.8 190.8 2498.8 302.7 2326.1 302.7">
                                            </polygon>
                                        </svg>
                                    </div>
                                    <div className="ay_box pd24 bg_white">
                                        <div className="ay_w_100 ay_grid_24">
                                            <div className="ay_w_100 ay_grid_12">
                                                <div className="ay_flex_6 ay_center ay_text_13 ay_bold_7">
                                                    <div className="ay_point0">24H</div>
                                                    <div className="">{changeNum(useCounter(Math.floor(BybitTotalTrade / 1000000)), 0)}M USDT</div>
                                                    {/* <div className="">Coming Soon</div> */}
                                                </div>
                                                <a href='https://www.bybit.com/en-us/promo/global/broker/' target='_blank' rel="noopener noreferrer" className='btn classic_op' style={{ textAlign: "center" }}>{t('main_18')}</a>
                                            </div>
                                            <div className="ay_text_8 ay_line_15 ay_point0 ay_banner3_desc">
                                                {t('main_19')}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ay_banner4">
                <div className="container">
                    <div className="ay_grid_48">
                        <div className="ay_grid_24 text_center">
                            <div className="ay_text_25 ay_bold_7 ay_line_12">
                                {t('main_20')}
                            </div>
                            <div className="ay_point0 ay_line_13">
                                {t('main_21')}
                            </div>
                        </div>
                        <div className="ay_banner4_content">
                            <div className="ay_banner4_flex">
                                <div className="ay_banner4_desc">
                                    <div className="ay_flex ay_center ay_icon_50 box">
                                        <svg viewBox="0 0 114.77 69.89" className="ay_icon_20">
                                            <path className="ay_banner4_one-1"
                                                d="m44.96,35.43c-3.48,3.5-26.84,26.9-30.22,30.28-2.23,2.23-4.07,2.24-6.33.02-1.49-1.46-2.98-2.94-4.44-4.44-1.9-1.96-1.97-3.91-.09-5.81,5.26-5.31,30.52-30.62,35.87-35.84,3.12-3.05,7.56-2.91,10.75.25,5.23,5.17,10.41,10.39,15.61,15.59.41.41.85.8,1.16,1.09,5.75-5.71,11.42-11.33,17.29-17.16-2.31-2.25-4.64-4.55-7-6.8-1.95-1.86-2.83-3.98-1.79-6.61.93-2.36,2.93-3.5,6.08-3.5,8.61,0,17.22,0,25.83,0,3.27,0,4.58,1.3,4.58,4.55,0,8.67-.03,17.34.02,26.01.02,2.72-.92,4.82-3.47,5.91-2.58,1.1-4.75.23-6.62-1.71-2.27-2.36-4.59-4.68-6.95-7.07-.61.58-1.09,1.02-1.55,1.48-6.77,6.76-13.53,13.53-20.3,20.29-3.85,3.84-8.08,3.85-11.9.03-5.45-5.45-10.89-10.92-16.51-16.56Z" />
                                        </svg>
                                    </div>
                                    <div className="ay_w_100 ay_grid_12">
                                        <div className="ay_text_13 ay_line_13">
                                            {t('main_22')}
                                        </div>
                                        <div className="ay_line_13 ay_point0">
                                            {t('main_23')}
                                        </div>
                                    </div>
                                </div>
                                <div className="ay_banner4_desc">
                                    <div className="ay_flex ay_center ay_icon_50 box">
                                        <svg viewBox="0 0 222.43 178.98" className="ay_icon_20">
                                            <path className="ay_banner4_tool-1"
                                                d="m112.59,4c3.72.71,5.65,2.7,7.19,5.16,32.42,52.05,64.9,104.08,97.32,156.13.79,1.27,1.46,2.78,1.31,4.13-.54,4.94-7.74,7.21-13.28,4.23-6.38-3.44-12.65-6.99-18.96-10.5-24.36-13.51-48.72-27.03-73.08-40.54-.59-.33-1.2-.65-1.88-1.02-9.37,5.2-18.68,10.36-27.99,15.53-21.71,12.05-43.42,24.1-65.14,36.14-5.38,2.99-11.55,1.89-13.61-2.41-.91-1.9-.35-3.68.76-5.47,10.27-16.41,20.5-32.84,30.75-49.26C58.19,80.52,80.43,44.91,102.6,9.28c1.43-2.31,3.33-4.13,6.38-5.28h3.61Z" />
                                        </svg>
                                    </div>
                                    <div className="ay_w_100 ay_grid_12">
                                        <div className="ay_text_13 ay_line_13">
                                            {t('main_24')}
                                        </div>
                                        <div className="ay_line_13 ay_point0">
                                            {t('main_25')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ay_banner4_flex">
                                <div className="ay_banner4_desc">
                                    <div className="ay_flex ay_center ay_icon_50 box">
                                        <svg viewBox="0 0 132.2 93.99" className="ay_icon_20">
                                            <path className="ay_banner4_ui-1"
                                                d="m78.18,35.35c0,8.19.94,16.5-.19,24.53-2.71,19.38-20.67,32.98-40.32,31.49-19.08-1.44-34.63-17.55-35.02-36.69-.27-13.1-.14-26.2-.06-39.3.05-7.39,5.68-12.93,12.77-12.88,7.06.05,12.55,5.64,12.6,13.08.08,12.04.01,24.09.03,36.13.01,8.46,5.1,14.25,12.56,14.33,7.64.08,12.84-5.75,12.85-14.44.01-11.78-.02-23.56.01-35.34.02-8.07,5.32-13.77,12.71-13.76,7.35,0,12.66,5.74,12.7,13.79.03,6.35,0,12.71,0,19.06-.22,0-.44,0-.66,0Z" />
                                            <path className="ay_banner4_ui-1"
                                                d="m104.27,46.56c0-10.04-.02-20.09,0-30.13.02-8.13,5.18-13.83,12.52-13.92,7.5-.1,12.88,5.69,12.89,13.97.02,20.35.02,40.71,0,61.06,0,8.1-5.21,13.84-12.53,13.93-7.49.1-12.86-5.7-12.88-13.98-.02-10.31,0-20.62,0-30.93Z" />
                                        </svg>
                                    </div>
                                    <div className="ay_w_100 ay_grid_12">
                                        <div className="ay_text_13 ay_line_13">
                                            {t('main_26')}
                                        </div>
                                        <div className="ay_line_13 ay_point0">
                                            {t('main_27')}
                                        </div>
                                    </div>
                                </div>
                                <div className="ay_banner4_desc">
                                    <div className="ay_flex ay_center ay_icon_50 box">
                                        <svg viewBox="0 0 380.2 380.5" className="ay_icon_20">
                                            <path className="ay_banner4_verified-2"
                                                d="m93.41,50.2c8.38.94,17.06,1.83,25.7,2.91,8.86,1.1,15.57-2.11,21.27-8.97,7.87-9.48,16.2-18.59,24.58-27.62,2.51-2.7,5.73-4.81,8.84-6.86,11.68-7.7,21.76-7.53,33.34.44,11.67,8.02,20.64,18.73,29.37,29.67,1.32,1.66,2.59,3.36,3.89,5.03,5.52,7.09,12.72,9.5,21.52,8.14,13.08-2.02,26.25-3.58,39.44-1.4,18.4,3.04,27.14,12.33,27.75,30.91.39,11.78-.4,23.67-1.74,35.4-1.1,9.67,1.34,17.03,9.14,22.89,11.69,8.77,22.96,18.07,31.81,29.87,5.21,6.95,9.52,14.6,7.24,23.48-1.48,5.74-4.33,11.44-7.79,16.29-8.33,11.69-19.51,20.64-30.93,29.14-8.12,6.04-10.85,13.59-9.4,23.48,2.03,13.83,3.3,27.78.55,41.68-2.97,15.05-11.08,22.19-26.42,24.1-13,1.62-25.93.77-38.77-1.16-9.89-1.49-17.45,1.29-23.49,9.4-8.43,11.31-17.43,22.17-28.71,30.87-14.87,11.46-26.08,11.5-40.97.08-11.3-8.67-20.34-19.51-28.75-30.83-6.12-8.25-13.76-10.97-23.81-9.48-13.7,2.04-27.55,3.3-41.32.59-15.29-3.01-22.36-11.13-24.26-26.69-1.59-13-.75-25.91,1.19-38.76,1.44-9.54-1.33-16.83-8.92-23.01-9.55-7.78-18.6-16.18-27.59-24.61-2.87-2.68-5.06-6.16-7.23-9.5-6.65-10.24-6.56-20.58-.02-30.89,6.16-9.71,14.42-17.48,23.09-24.84,4.09-3.47,8.33-6.75,12.59-10.02,7.04-5.4,9.1-12.47,8.26-21.14-1.11-11.5-1.77-23.07-2-34.63-.09-4.5,1.07-9.17,2.39-13.54,3.37-11.14,11.78-16.65,22.71-18.59,5.62-1,11.38-1.21,17.43-1.82Z" />
                                            <path className="ay_banner4_verified-1"
                                                d="m178.75,209.1c21.16-21.03,42.12-41.91,63.13-62.75,8.12-8.05,19.77-8.31,27.6-.76,7.42,7.17,8.19,18.25,1.54,26.19-3,3.58-6.45,6.78-9.75,10.09-22.19,22.22-44.43,44.39-66.58,66.65-5.8,5.83-12.37,9.46-20.66,6.99-3.46-1.03-7-3.06-9.59-5.58-12.69-12.29-25.13-24.83-37.54-37.4-8.32-8.42-8.47-19.98-.6-27.91,8-8.06,19.67-7.81,28.26.63,8.07,7.93,16.11,15.87,24.2,23.85Z" />
                                        </svg>
                                    </div>
                                    <div className="ay_w_100 ay_grid_12">
                                        <div className="ay_text_13 ay_line_13">
                                            {t('main_28')}
                                        </div>
                                        <div className="ay_line_13 ay_point0">
                                            {t('main_29')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ay_banner5">
                <div className="container">
                    <div className="ay_w_100 ay_grid_48">
                        <div className="ay_grid_24 text_center">
                            <div className="ay_text_25 ay_bold_7 ay_line_12">
                                {t('main_30')}
                            </div>
                            <div className="ay_point0 ay_line_13">
                                {t('main_31')}
                            </div>
                        </div>
                        <div className="ay_banner5_wrap">
                            <div className="ay_banner5_desc">
                                <div className="ay_grid_24">
                                    <div className="ay_text_13">
                                        {t('main_32')}
                                    </div>
                                    <div className="ay_point0 ay_line_13">
                                        {t('main_33')}
                                    </div>
                                    <div className="ay_flex_24">
                                        <Link to={`/futures/binance/BTCUSDT`} style={{ width: "100%" }}>
                                            <button type="button" className="btn classic_op pd_1612 ay_banner5_btn">
                                                <div className="ay_grid_12">
                                                    <svg viewBox="0 0 126.61 126.61" className="ay_icon_30 mg_auto">
                                                        <path className="ay_banner5_binance-1" d="m38.73,53.2l24.59-24.58,24.6,24.6,14.3-14.31L63.32,0,24.42,38.9l14.31,14.3Z" />
                                                        <path className="ay_banner5_binance-1" d="m0,63.31l14.3-14.3,14.3,14.3-14.3,14.3L0,63.31Z" />
                                                        <path className="ay_banner5_binance-1" d="m38.73,73.41l24.59,24.59,24.6-24.6,14.31,14.29h-.01s-38.9,38.92-38.9,38.92l-38.9-38.89-.02-.02,14.33-14.29Z" />
                                                        <path className="ay_banner5_binance-1" d="m98,63.31l14.3-14.3,14.3,14.3-14.3,14.3-14.3-14.3Z" />
                                                        <path className="ay_banner5_binance-1" d="m77.83,63.3l-14.51-14.52-10.73,10.73-1.24,1.23-2.54,2.54-.02.02.02.03,14.51,14.5,14.51-14.52h.01s-.01-.01-.01-.01Z" />
                                                    </svg>
                                                    <div className="ay_text_8">{t('main_34')}</div>
                                                </div>
                                            </button>
                                        </Link>
                                        <Link to={`/futures/okx/BTC-USDT-SWAP`} style={{ width: "100%" }}>
                                            <button type="button" className="btn classic_op pd_1612 ay_banner5_btn">
                                                <div className="ay_grid_12">
                                                    <svg viewBox="0 0 133.86 125.25" className="ay_icon_30 mg_auto">
                                                        <path className="ay_banner5_okx-1" d="m86.25,41.75h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78h0Z" />
                                                        <path className="ay_banner5_okx-1" d="m41.64,0H2.97C1.33,0,0,1.25,0,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.98-1.25,2.98-2.78V2.78C44.62,1.25,43.28,0,41.64,0Z" />
                                                        <path className="ay_banner5_okx-1" d="m130.89,0h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78V2.78C133.86,1.25,132.53,0,130.89,0Z" />
                                                        <path className="ay_banner5_okx-1" d="m41.64,83.5H2.97c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.98-1.25,2.98-2.78v-36.18c0-1.54-1.33-2.78-2.98-2.78Z" />
                                                        <path className="ay_banner5_okx-1" d="m130.89,83.5h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78Z" />
                                                    </svg>
                                                    <div className="ay_text_8">{t('main_35')}</div>
                                                </div>
                                            </button>
                                        </Link>
                                        <Link to={Bybit_Mode ? `/futures/bybit/BTCUSDT` : ""} style={{ width: "100%" }}>
                                            <button type="button" className={`btn classic_op pd_1612 ay_banner5_btn ${Bybit_Mode ? "" : "ay_deactivation"}`}>
                                                <div className="ay_grid_12">
                                                    <svg viewBox="0 0 1338.1 833" className="ay_icon_30 mg_auto">
                                                        <polygon className="ay_banner5_bybit-2" points="620.3 642.2 620.3 0 749.4 0 749.4 642.2 620.3 642.2" />
                                                        <path className="ay_banner5_bybit-1" d="m276.7,832.9H0V190.8h265.6c129,0,204.3,70.4,204.3,180.4,0,71.3-48.3,117.2-81.8,132.6,39.9,18,91,58.6,91,144.3,0,119.8-84.4,184.8-202.4,184.8h0Zm-21.3-530.2h-126.3v147.9h126.3c54.8,0,85.5-29.8,85.5-74-.1-44.2-30.8-73.9-85.5-73.9Zm8.3,260.6h-134.6v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4s-27.9-78.4-86.4-78.4h0Z" />
                                                        <polygon className="ay_banner5_bybit-1" points="1165.4 302.7 1165.4 833 1036.3 833 1036.3 302.7 863.6 302.7 863.6 190.8 1338.1 190.8 1338.1 302.7 1165.4 302.7" />
                                                    </svg>
                                                    <div className="ay_text_8">{t('main_36')}</div>
                                                </div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="ay_grid_24">
                                    <div className="ay_text_13">
                                        {t('main_37')}
                                    </div>
                                    <div className="ay_point0 ay_line_13">
                                        {t('main_38')}
                                    </div>
                                    <div className="ay_flex_24">
                                        <Link to={`/spot/binance/BTCUSDT`} style={{ width: "100%" }}>
                                            <button type="button" className="btn classic_op pd_1612 ay_banner5_btn">
                                                <div className="ay_grid_12">
                                                    <svg viewBox="0 0 126.61 126.61" className="ay_icon_30 mg_auto">
                                                        <path className="ay_banner5_binance-1" d="m38.73,53.2l24.59-24.58,24.6,24.6,14.3-14.31L63.32,0,24.42,38.9l14.31,14.3Z" />
                                                        <path className="ay_banner5_binance-1" d="m0,63.31l14.3-14.3,14.3,14.3-14.3,14.3L0,63.31Z" />
                                                        <path className="ay_banner5_binance-1" d="m38.73,73.41l24.59,24.59,24.6-24.6,14.31,14.29h-.01s-38.9,38.92-38.9,38.92l-38.9-38.89-.02-.02,14.33-14.29Z" />
                                                        <path className="ay_banner5_binance-1" d="m98,63.31l14.3-14.3,14.3,14.3-14.3,14.3-14.3-14.3Z" />
                                                        <path className="ay_banner5_binance-1" d="m77.83,63.3l-14.51-14.52-10.73,10.73-1.24,1.23-2.54,2.54-.02.02.02.03,14.51,14.5,14.51-14.52h.01s-.01-.01-.01-.01Z" />
                                                    </svg>
                                                    <div className="ay_text_8">{t('main_39')}</div>
                                                </div>
                                            </button>
                                        </Link>
                                        <Link to={`/spot/okx/BTC-USDT`} style={{ width: "100%" }}>
                                            <button type="button" className="btn classic_op pd_1612 ay_banner5_btn">
                                                <div className="ay_grid_12">
                                                    <svg viewBox="0 0 133.86 125.25" className="ay_icon_30 mg_auto">
                                                        <path className="ay_banner5_okx-1" d="m86.25,41.75h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78h0Z" />
                                                        <path className="ay_banner5_okx-1" d="m41.64,0H2.97C1.33,0,0,1.25,0,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.98-1.25,2.98-2.78V2.78C44.62,1.25,43.28,0,41.64,0Z" />
                                                        <path className="ay_banner5_okx-1" d="m130.89,0h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78V2.78C133.86,1.25,132.53,0,130.89,0Z" />
                                                        <path className="ay_banner5_okx-1" d="m41.64,83.5H2.97c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.98-1.25,2.98-2.78v-36.18c0-1.54-1.33-2.78-2.98-2.78Z" />
                                                        <path className="ay_banner5_okx-1" d="m130.89,83.5h-38.67c-1.64,0-2.97,1.25-2.97,2.78v36.18c0,1.54,1.33,2.78,2.97,2.78h38.67c1.64,0,2.97-1.25,2.97-2.78v-36.18c0-1.54-1.33-2.78-2.97-2.78Z" />
                                                    </svg>
                                                    <div className="ay_text_8">{t('main_39')}</div>
                                                </div>
                                            </button>
                                        </Link>
                                        <Link to={Bybit_Mode ? `/spot/bybit/BTCUSDT` : ''} style={{ width: "100%" }}>
                                            <button type="button" className={`btn classic_op pd_1612 ay_banner5_btn ${Bybit_Mode ? "" : "ay_deactivation"}`}>
                                                <div className="ay_grid_12">
                                                    <svg viewBox="0 0 1338.1 833" className="ay_icon_30 mg_auto">
                                                        <polygon className="ay_banner5_bybit-2" points="620.3 642.2 620.3 0 749.4 0 749.4 642.2 620.3 642.2" />
                                                        <path className="ay_banner5_bybit-1" d="m276.7,832.9H0V190.8h265.6c129,0,204.3,70.4,204.3,180.4,0,71.3-48.3,117.2-81.8,132.6,39.9,18,91,58.6,91,144.3,0,119.8-84.4,184.8-202.4,184.8h0Zm-21.3-530.2h-126.3v147.9h126.3c54.8,0,85.5-29.8,85.5-74-.1-44.2-30.8-73.9-85.5-73.9Zm8.3,260.6h-134.6v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4s-27.9-78.4-86.4-78.4h0Z" />
                                                        <polygon className="ay_banner5_bybit-1" points="1165.4 302.7 1165.4 833 1036.3 833 1036.3 302.7 863.6 302.7 863.6 190.8 1338.1 190.8 1338.1 302.7 1165.4 302.7" />
                                                    </svg>
                                                    <div className="ay_text_8">{t('main_39')}</div>
                                                </div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="ay_banner5_img">
                                <img src="/img/newmain/main/ay_banner5_bg.svg" style={{ width: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="ay_banner1">
                <div className="narrow_container">
                    <div className="ay_grid_48 text_center">
                        <div className="ay_grid_24">
                            <div className="ay_grid_12">
                                <div className="ay_text_13">
                                    {t('main_40')}
                                </div>
                                <div className="ay_text_25 ay_bold_7 ay_line_12">
                                    {t('main_41')}<br />
                                    {t('main_42')}
                                </div>
                            </div>
                            <div className="ay_point0 ay_line_13">
                                {t('main_43')}
                            </div>
                        </div>
                        <button type="button" className="btn basic ay_w_fit pd_16 mg_auto">
                            {t('main_44')}
                        </button>
                    </div>
                </div>
            </div> */}
            <div className="ay_banner2">
                <div className="container">
                    <div className="ay_w_100 ay_grid_24">
                        <div className="ay_text_25 ay_bold_7 ay_line_12 text_center">
                            {t('main_45')}
                        </div>
                        <div className="ay_accordion_group">
                            <button type="button" className={`ay_accordion ay_bold_5 ${btn[0] ? "active" : ""}`} onClick={() => handleButtonClick(0)}>
                                <div className="num">1</div>
                                {t('main_47')}
                            </button>
                            <div className="ay_accordion_content" style={{ maxHeight: btn[0] ? `${divHeight[0]}px` : "0" }}>
                                <div className="pd_24 ay_point0 ay_line_13" ref={btnRef0}>
                                    {t('main_48')}
                                </div>
                            </div>
                            <button type="button" className={`ay_accordion ay_bold_5 ${btn[1] ? "active" : ""}`} onClick={() => handleButtonClick(1)}>
                                <div className="num">2</div>
                                {t('main_49')}
                            </button>
                            <div className="ay_accordion_content" style={{ maxHeight: btn[1] ? `${divHeight[1]}px` : "0" }}>
                                <div className="pd_24 ay_point0 ay_line_13" ref={btnRef1}>
                                    {t('main_50')}
                                </div>
                            </div>
                            <button type="button" className={`ay_accordion ay_bold_5 ${btn[2] ? "active" : ""}`} onClick={() => handleButtonClick(2)}>
                                <div className="num">3</div>
                                {t('main_51')}
                            </button>
                            <div className="ay_accordion_content" style={{ maxHeight: btn[2] ? `${divHeight[2]}px` : "0" }}>
                                <div className="pd_24 ay_point0 ay_line_13" ref={btnRef2}>
                                    {t('main_52')}
                                </div>
                            </div>
                            <button type="button" className={`ay_accordion ay_bold_5 ${btn[3] ? "active" : ""}`} onClick={() => handleButtonClick(3)}>
                                <div className="num">4</div>
                                {t('main_55')}
                            </button>
                            <div className="ay_accordion_content" style={{ maxHeight: btn[3] ? `${divHeight[3]}px` : "0" }}>
                                <div className="pd_24 ay_point0 ay_line_13" ref={btnRef3}>
                                    {t('main_56')}
                                </div>
                            </div>
                            {/* <button type="button" className={`ay_accordion ay_bold_5 ${btn[4] ? "active" : ""}`} onClick={() => handleButtonClick(4)}>
                                <div className="num">5</div>
                                {t('main_53')}
                            </button>
                            <div className="ay_accordion_content" style={{ maxHeight: btn[4] ? `${divHeight[4]}px` : "0" }}>
                                <div className="pd_24 ay_point0 ay_line_13" ref={btnRef4}>
                                    {t('main_54')}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ay_banner1">
                <div className="narrow_container">
                    <div className="ay_grid_48 text_center">
                        <div className="ay_grid_24">
                            <div className="ay_grid_12">
                                <div className="ay_text_25 ay_bold_7 ay_line_12">{t('main_58')}</div>
                            </div>
                            <div className="ay_point0 ay_line_13">
                                {
                                    lang_arr == "vi"
                                        ?
                                        <>
                                            {t('main_59')}<br />
                                            {t('main_59_1')}
                                        </>
                                        :
                                        <>
                                            {t('main_59')}
                                        </>
                                }
                            </div>
                        </div>
                        <a href={`https://namubit.zendesk.com/hc/${lang_arr}/requests/new`} className="btn basic ay_w_fit pd_16 mg_auto" target='_blank'>{t('main_60')}</a>
                        {/* <a href='mailto:support@coinrf.io' className="btn basic ay_w_fit pd_16 mg_auto">{t('main_60')}</a> */}
                    </div>
                </div>
            </div>
        </main >
    );
}

function TopTradeMarkets({ i, item }) {
    let dispatch = useDispatch()
    let imgcheck = true
    if (IMG && !IMG.includes(item.ccy)) {
        imgcheck = false
    }

    let type = item.exch == "OKX" ? "okx" : "binance"

    return (
        <Link to={`/futures/${type}/${item.symbol}`} onClick={() => { dispatch(checkSelectType(type == "okx" ? 0 : 1)) }}>
            <button type="button" className="ay_w_100 ay_grid_12 ay_table_content">
                <div className="ay_w_100 ay_flex_12">
                    <div className="ay_w_5 ay_text_8 ay_bold_7">
                        {i + 1}
                    </div>
                    <div className="ay_w_35 ay_flex_6">
                        <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} className="ay_table_market" />
                        <div className="ay_grid_6 ay_right text_left">
                            <div className="ay_bold_7">{item.ccy}USDT</div>
                            <div className="ay_text_8 ay_point0">{item.exch}</div>
                        </div>
                    </div>
                    <div className="ay_w_25 ay_grid_6 ay_right text_right">
                        <div className="ay_bold_5">{changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                        <div className="ay_text_8 ay_point0">${changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                    </div>
                    {
                        Number(item.priceChangePercent) >= 0
                            ?
                            <div className="ay_w_35 text_right ay_green">
                                +{Number(item.priceChangePercent).toFixed(2)}%
                            </div>

                            :
                            <div className="ay_w_35 text_right ay_red">
                                {Number(item.priceChangePercent).toFixed(2)}%
                            </div>
                    }
                </div>
            </button>
        </Link>
    )
}

function Event_Modal({ setEvent, lang_arr }) {
    const { t } = useTranslation();
    return (
        <>
            <div className='dashboard-right-bottom1-modal' style={{ backdropFilter: "blur(3px)" }} onClick={() => setEvent(false)}></div>
            <div className='event-modal'>
                <div className='dashboard-modal-security-1-box-2' onClick={() => setEvent(false)}><FontAwesomeIcon icon={faXmark} style={{ color: "#fff" }} /></div>
                <img src={`${lang_arr == "ko-kr" ? '/img/event/NAMUBIT_Bybit_Launch_KR.jpeg' :
                    lang_arr == "ja-jp" ? '/img/event/NAMUBIT_Bybit_Launch_JP.png' : '/img/event/NAMUBIT_Bybit_Launch_EN.jpeg'}`} />
                <div className='event-modal-1'>
                    <Link to={`/spot/bybit/BTCUSDT`} className='event-modal-2'></Link>
                    <Link to={`/futures/bybit/BTCUSDT`} className='event-modal-3'></Link>
                </div>
            </div>
        </>
    )
}

function isNumericString(str) {
    return !isNaN(str);
}

export default NewMain;