import { widget, ChartingLibraryWidgetOptions, LanguageCode, ResolutionString, CustomTimezones } from '../../charting_library';
import * as React from 'react';
import BinanceDataFeed from "../../services/binance/datafeed";
import BinanceFuturesDataFeed from "../../services/binance-f/datafeed";
import OKXDataFeed from "../../services/okx/datafeed";
import OKXDataSwapFeed from "../../services/okx-f/datafeed";
import BybitDataFeed from "../../services/bybit/datafeed";
import BybitFuturesDataFeed from "../../services/bybit-f/datafeed";
import { useEffect, useRef, useState } from "react";
import "./index.css";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux'
import Loader from "react-spinners/ClipLoader";

let API_URL = "";
let backColor: any;

let tvWidget: any;
let position: any;
let liqPos: any;
let markPos: any;

let orderData: any;
let orderLines: any = [];

let instData: any;
let ctVal: any;

let dataFeedInstance: any;
let exId = "Binance";

const markColor = "#f7a600";
const liqColor = "#ee16fa";
const shortColor = "#db1414";
const longColor = "#099c31";

export const TVChartContainer = (props: Partial<ChartingLibraryWidgetOptions>) => {
	const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	let API_Address = useSelector((state: any) => { return state.API_Address.value });

	let Binance_position = useSelector((state: any) => { return state.Binance_position });
	let Bn_markPrice = useSelector((state: any) => { return state.Bn_markPrice });
	let Bn_openorder = useSelector((state: any) => { return state.Bn_openorder });

	let OKX_position = useSelector((state: any) => { return state.OKX_position });
	let OKX_openorder = useSelector((state: any) => { return state.OKX_openorder });
	let OKX_markPrice = useSelector((state: any) => { return state.OKX_markPrice });
	let OKX_Instruments = useSelector((state: any) => { return state.OKX_Instruments });

	let Bybit_position = useSelector((state: any) => { return state.Bybit_position });
	let Bybit_openorder = useSelector((state: any) => { return state.Bybit_openorder });
	let Bybit_markPrice = useSelector((state: any) => { return state.Bybit_markPrice });
	let BybitF = useSelector((state: any) => { return state.Bybit_coinF });

	const [isChartReady, setIsChartReady] = useState(false);
	const [tvReady, setTVReady] = useState(false)
	let id = props.id ?? "undefined";
	let type = props.type ?? "1";
	let symbol = props.symbol ? props.symbol?.toUpperCase() + "/USDT" : "BTC/USDT";

	useEffect(() => {
		if (tvReady) {
			tvWidget.changeTheme(props.mode);
			if (API_Address == "dev") {
				if (props.mode == "dark") {
					setTimeout(() => {
						tvWidget.applyOverrides({
							"paneProperties.background": "#101014",
							"paneProperties.backgroundType": "solid",
							"paneProperties.vertGridProperties.color": "#cccccc16",
							"paneProperties.horzGridProperties.color": "#cccccc16"
						});
					}, 1)
					tvWidget.setCSSCustomProperty('--tv-color-platform-background', '#000000');
					tvWidget.setCSSCustomProperty('--tv-color-pane-background', '#101014');
					tvWidget.setCSSCustomProperty('--tv-color-popup-background', '#101014');
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-hover', '#101014');
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-expanded', '#101014');
					tvWidget.setCSSCustomProperty('--tv-color-popup-element-text-active', '#d1d4dc');
					tvWidget.setCSSCustomProperty('--tv-color-popup-element-background-active', '#2a2e39');
				}
				else {
					tvWidget.setCSSCustomProperty('--tv-color-platform-background', ' #f5f7fa');
					tvWidget.setCSSCustomProperty('--tv-color-pane-background', '#ffffff');
					tvWidget.setCSSCustomProperty('--tv-color-popup-background', '#ffffff');
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-hover', '#ffffff');
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-expanded', '#ffffff');
					tvWidget.setCSSCustomProperty('--tv-color-popup-element-text-active', '#131722');
					tvWidget.setCSSCustomProperty('--tv-color-popup-element-background-active', '#f0f3fa');
				}
			}

			tvWidget.activeChart().setSymbol(`${exId}:${props.symbol}/USDT`, () => {
				if (type === "2" && id !== "undefined") {
					position.remove();
					liqPos.remove();
					markPos.remove();

					position = tvWidget.activeChart().createPositionLine().setPrice(0);
					liqPos = tvWidget.activeChart().createPositionLine().setPrice(0);
					markPos = tvWidget.activeChart().createPositionLine().setPrice(0);
				}
			})
		}
		if (OKX_Instruments?.length > 0 && props.exid == "okx") {
			instData = OKX_Instruments.filter((item: any) => item.instId == symbol.replace("/USDT", "-USDT-SWAP"));
			ctVal = Number(instData[0]?.ctVal);
		}
	}, [props.mode, props.symbol, OKX_Instruments])

	// Bybit Data
	const bybitPublicWebSocket = () => {
		let data = Bybit_markPrice;
		if (data) {
			markPos
				.setText("")
				.setLineLength(0)
				.setLineStyle(3)
				.setLineColor(markColor)
				.setBodyTextColor(markColor)
				.setBodyBorderColor(markColor)
				.setQuantityBorderColor(markColor)
				.setQuantityBackgroundColor(markColor)
				.setQuantity("")
				.setPrice(data);
		}
	};

	const bybitPositionWebSocket = () => {
		let bnData = Bybit_position
		let cup = bnData.filter((coinData: any) => coinData.symbol === `${symbol.replace("/", "").toUpperCase()}`);
		let coin = BybitF.filter((coinData: any) => coinData.symbol === `${symbol.replace("/", "").toUpperCase()}`);

		let entryPrice = cup[0]?.avgPrice ? cup[0]?.avgPrice : cup[0]?.entryPrice ? cup[0]?.entryPrice : ""
		let pnl = ((Number(coin[0]?.lastPrice ?? entryPrice) - Number(entryPrice)) * Number(cup[0]?.size ?? 0)).toFixed(6)


		// console.log("----------------------------------------------")
		// console.log("Bybit EntryPrice", entryPrice)

		if (cup?.length > 0) {
			let color = cup[0].side == "Sell" ? shortColor : longColor;
			position
				.setText(Math.abs(cup[0].size) + "")
				.setExtendLeft(false)
				.setLineLength(70)
				.setLineStyle(0) // 0: 실선, 1~5: 점선 , 6 이후는 없음
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(pnl)
				.setPrice(Number(entryPrice));
			liqPos.setText(`Liq`).setExtendLeft(false).setLineLength(70).setLineStyle(0).setLineColor(liqColor).setBodyTextColor("black").setBodyBorderColor(liqColor).setQuantityBorderColor(liqColor).setQuantityBackgroundColor(liqColor).setQuantity("-").setPrice(Number(cup[0].liqPrice));
		}
		else if (cup?.length == 0) {
			let color = longColor;
			position
				.setText(0 + "")
				.setExtendLeft(false)
				.setLineLength(70)
				.setLineStyle(0)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(0)
				.setPrice(0);
			liqPos.setText(`Liq`).setExtendLeft(false).setLineLength(70).setLineStyle(0).setLineColor(liqColor).setBodyTextColor("black").setBodyBorderColor(liqColor).setQuantityBorderColor(liqColor).setQuantityBackgroundColor(liqColor).setQuantity("-").setPrice(0);
		}
	};

	const bybitOpenOrderWebSocket = async () => {
		orderData = Bybit_openorder.filter((item: any) => item.symbol == symbol.replace("/", ""))
		orderLines.forEach((element: any) => {
			element.remove();
		});
		orderLines = [];

		orderData?.forEach((orderLine: any) => {
			let color = orderLine.side === "Buy" ? longColor : shortColor;
			let price: any = Number(orderLine.px);
			let tv_orderData = tvWidget
				.activeChart()
				.createOrderLine()
				.setText(`${orderLine.orderType}-${orderLine.timeInForce}`)
				.setExtendLeft(false)
				.setLineLength(100)
				.setLineStyle(5)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(Number(orderLine.sz) - Number(orderLine.executedQty) + "")
				.setPrice(price);

			if (orderLine.orderType === "Limit") {
				tv_orderData.setEditable(true).onMove(async function () {
					let pp = tv_orderData.getPrice();
					let amendResult = await bybitAmendOrder(orderLine.symbol, orderLine.orderId, pp);
					if (amendResult?.code != 1000) {
						alert("Failed");
						tv_orderData.setPrice(price);
					}
				});
			}
			else if (orderLine.orderType === "Stop") {
				tv_orderData.setEditable(true).onMove(async function () {
					let pp = tv_orderData.getPrice();
					let amendResult = await bybitAlgoAmendOrder(orderLine.symbol, orderLine.orderId, pp);
					if (amendResult?.code != 1000) {
						alert("MODIFY FAILED.");
						tv_orderData.setPrice(price);
					}
				});
			}
			tv_orderData.setCancellable(true).onCancel(async function () {
				let amendResult = await bybitCancelOrder(orderLine.symbol, orderLine.orderId);
				if (amendResult.code !== 1000) {
					alert("CANCEL FAILED.");
					tv_orderData.setPrice(price);
				}
			});
			orderLines.push(tv_orderData);
		});
	};

	useEffect(() => {
		if (Bybit_openorder && tvReady && id !== "undefined" && props.exid == "bybit") {
			setTimeout(() => {
				bybitOpenOrderWebSocket()
			}, 2000)
		}
	}, [Bybit_openorder, props.symbol, tvReady])

	useEffect(() => {
		if (Bybit_position && BybitF && tvReady && id !== "undefined" && position && liqPos && props.exid == "bybit") {
			bybitPositionWebSocket()
		}
	}, [Bybit_position, BybitF, props.symbol, tvReady])

	useEffect(() => {
		if (Bybit_markPrice && tvReady && id !== "undefined" && markPos && props.exid == "bybit") {
			bybitPublicWebSocket()
		}
	}, [Bybit_markPrice, props.symbol, tvReady])


	// Binance Data
	const binancePositionWebSocket = () => {
		let bnData = Binance_position

		let cup = bnData.filter((coinData: any) => coinData.symbol === `${symbol.replace("/", "").toUpperCase()}`);
		if (cup.length > 0) {
			let color = Number(cup[0].positionAmt) < 0 ? shortColor : longColor;
			position
				.setText(Math.abs(cup[0].positionAmt) + "")
				.setExtendLeft(false)
				.setLineLength(70)
				.setLineStyle(0)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(cup[0].unRealizedProfit)
				.setPrice(Number(cup[0].entryPrice));
			liqPos.setText(`Liq`).setExtendLeft(false).setLineLength(70).setLineStyle(0).setLineColor(liqColor).setBodyTextColor("black").setBodyBorderColor(liqColor).setQuantityBorderColor(liqColor).setQuantityBackgroundColor(liqColor).setQuantity("-").setPrice(Number(cup[0].liquidationPrice));
		}
		else if (cup?.length == 0) {
			let color = longColor;
			position
				.setText(0 + "")
				.setExtendLeft(false)
				.setLineLength(70)
				.setLineStyle(0)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(0)
				.setPrice(0);
			liqPos.setText(`Liq`).setExtendLeft(false).setLineLength(70).setLineStyle(0).setLineColor(liqColor).setBodyTextColor("black").setBodyBorderColor(liqColor).setQuantityBorderColor(liqColor).setQuantityBackgroundColor(liqColor).setQuantity("-").setPrice(0);
		}
	};

	const binancePublicWebSocket = () => {
		let data = Bn_markPrice;
		if (data?.e === "markPriceUpdate") {
			markPos
				.setText("")
				.setLineLength(0)
				.setLineStyle(3)
				.setLineColor(markColor)
				.setBodyTextColor(markColor)
				.setBodyBorderColor(markColor)
				.setQuantityBorderColor(markColor)
				.setQuantityBackgroundColor(markColor)
				.setQuantity("")
				.setPrice(data.p);
		}
	};

	const binanceOpenOrderWebSocket = async () => {
		orderData = Bn_openorder.filter((item: any) => item.symbol == symbol.replace("/", ""))

		orderLines.forEach((element: any) => {
			element.remove();
		});
		orderLines = [];

		orderData?.forEach((orderLine: any) => {
			let color = orderLine.side === "BUY" ? longColor : shortColor;
			let price: any = Number(orderLine.price);
			let tv_orderData = tvWidget
				.activeChart()
				.createOrderLine()
				.setText(`${orderLine.type}-${orderLine.timeInForce}`)
				.setExtendLeft(false)
				.setLineLength(100)
				.setLineStyle(5)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(Number(orderLine.origQty) - Number(orderLine.executedQty) + "")
				.setPrice(price);
			if (orderLine.type === "LIMIT") {
				tv_orderData.setEditable(true).onMove(async function () {
					let pp = tv_orderData.getPrice();
					let amendResult = await bnModifyOrder(id, orderLine.symbol, orderLine.orderId, orderLine.side, orderLine.origQty, pp);
					if (amendResult.code < 0) {
						alert(amendResult.msg);
						tv_orderData.setPrice(price);
					}
				});
			} else if (orderLine.type === "STOP_MARKET") {
				tv_orderData.setEditable(true).onMove(async function () {
					let pp = tv_orderData.getPrice();
					let amendResult = await bnOrder(id, orderLine.symbol, orderLine.side, orderLine.type, orderLine.reduceOnly, orderLine.origQty, pp, orderLine.timeInForce);
					if (amendResult.status === "NEW") {
						amendResult = await bnCancelOrder(id, orderLine.symbol, orderLine.orderId);
						if (amendResult.code < 0) {
							alert(amendResult.msg);
							tv_orderData.setPrice(price);
						}
					} else {
						alert("MODIFY FAILED.");
						tv_orderData.setPrice(price);
					}
				});
			}
			tv_orderData.setCancellable(true).onCancel(async function () {
				let amendResult = await bnCancelOrder(id, orderLine.symbol, orderLine.orderId);
				if (amendResult !== "CANCELED") {
					alert("CANCEL FAILED.");
					tv_orderData.setPrice(price);
				}
			});
			orderLines.push(tv_orderData);
		});
	};

	useEffect(() => {
		if (Binance_position && tvReady && id !== "undefined" && position && liqPos && props.exid == "Binance") {
			binancePositionWebSocket()
		}
	}, [Binance_position, props.symbol])

	useEffect(() => {
		if (Bn_markPrice && tvReady && id !== "undefined" && markPos && props.exid == "Binance") {
			binancePublicWebSocket()
		}
	}, [Bn_markPrice, props.symbol])

	useEffect(() => {
		if (Bn_openorder && tvReady && id !== "undefined" && props.exid == "Binance") {
			setTimeout(() => {
				binanceOpenOrderWebSocket()
			}, 2000)
		}
	}, [Bn_openorder, props.symbol])

	// OKX Data
	const okxPositionWebSocket = () => {
		let bnData = OKX_position;

		if (bnData.length > 0) {
			let color = Number(bnData[0].pos) < 0 ? shortColor : longColor;
			position
				.setText(Math.abs(Number(bnData[0].pos) * ctVal) + "")
				.setExtendLeft(false)
				.setLineLength(70)
				.setLineStyle(0)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(Number(Number(bnData[0].uplLastPx).toFixed(4)) + "")
				.setPrice(Number(bnData[0].avgPx));

			liqPos.setText(`Liq`).setExtendLeft(false).setLineLength(70).setLineStyle(0).setLineColor(liqColor).setBodyTextColor("black").setBodyBorderColor(liqColor).setQuantityBorderColor(liqColor).setQuantityBackgroundColor(liqColor).setQuantity("-").setPrice(Number(bnData[0].liqPx));
		}
		else {
			let color = shortColor;
			position
				.setText(0 + "")
				.setExtendLeft(false)
				.setLineLength(70)
				.setLineStyle(0)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(0)
				.setPrice(0);

			liqPos.setText(`Liq`).setExtendLeft(false).setLineLength(70).setLineStyle(0).setLineColor(liqColor).setBodyTextColor("black").setBodyBorderColor(liqColor).setQuantityBorderColor(liqColor).setQuantityBackgroundColor(liqColor).setQuantity("-").setPrice(0);
		}
	};

	const okxPublicWebSocket = () => {
		if (OKX_markPrice) {
			markPos.setText("")
				.setLineLength(0)
				.setLineStyle(3)
				.setLineColor(markColor)
				.setBodyTextColor(markColor)
				.setBodyBorderColor(markColor)
				.setQuantityBorderColor(markColor)
				.setQuantityBackgroundColor(markColor)
				.setQuantity("").setPrice(OKX_markPrice);
		};
	}

	const okxOpenOrderWebSocket = async () => {
		orderData = OKX_openorder.filter((item: any) => item.instId == symbol.replace("/USDT", "-USDT-SWAP"))
		orderLines.forEach((element: any) => {
			element.remove();
		});
		orderLines = [];
		orderData?.forEach((orderLine: any) => {
			let color = orderLine.side.toUpperCase() === "BUY" ? longColor : shortColor;
			let type = orderLine.ordType.toUpperCase();
			if (type === "TRIGGER") {
				type = "STOP MARKET";
			} else if (type === "LIMIT") {
				type = "LIMIT - GTC";
			} else if (type === "POST_ONLY") {
				type = "LIMIT - GTX";
			}
			let tv_orderData = tvWidget
				.activeChart()
				.createOrderLine()
				.setText(type)
				.setExtendLeft(false)
				.setLineLength(100)
				.setLineStyle(5)
				.setLineColor(color)
				.setBodyTextColor(color)
				.setBodyBorderColor(color)
				.setQuantityBorderColor(color)
				.setQuantityBackgroundColor(color)
				.setQuantity(Number(orderLine.sz) * ctVal + "");
			if (orderLine.ordType !== "conditional") {
				tv_orderData.setEditable(true).onMove(async function () {
					let pp = tv_orderData.getPrice();
					let amendResult;
					if (orderLine.ordType.toUpperCase() === "LIMIT" || orderLine.ordType.toUpperCase() === "POST_ONLY") {
						amendResult = await okxAmendOrder(id, orderLine.instId, orderLine.ordId, pp);
					} else {
						if (orderLine.ordType === "trigger") {
							amendResult = await okxCancelAlgoOrder(id, orderLine.instId, orderLine.algoId);
							amendResult = await okxAlgoOrder(id, orderLine.instId, orderLine.tdMode, orderLine.side, orderLine.ordType, orderLine.sz, pp);
						}
					}
					if (amendResult.sCode !== "0") {
						alert(amendResult.sMsg);
						tv_orderData.setPrice(Number(orderLine.px));
					}
				});
			}
			tv_orderData.setCancellable(true).onCancel(async function () {
				let amendResult;
				if (orderLine.ordType.toUpperCase() === "LIMIT" || orderLine.ordType.toUpperCase() === "POST_ONLY") {
					amendResult = await okxCancelOrder(id, orderLine.instId, orderLine.ordId);
				} else {
					if (orderLine.ordType === "trigger") {
						amendResult = await okxCancelAlgoOrder(id, orderLine.instId, orderLine.algoId);
					} else if (orderLine.ordType === "conditional") {
						amendResult = await okxCancelAlgoOrder(id, orderLine.instId, orderLine.algoId);
					}
				}

				if (amendResult.sCode !== "0") {
					alert(amendResult.sMsg);
					tv_orderData.setPrice(Number(orderLine.px));
				}
			});

			tv_orderData.setPrice(Number(orderLine.px));
			orderLines.push(tv_orderData);
		});
	};

	useEffect(() => {
		if (OKX_position && tvReady && id !== "undefined" && position && liqPos && props.exid == "okx") {
			okxPositionWebSocket()
		}
	}, [OKX_position])

	useEffect(() => {
		if (OKX_markPrice && tvReady && id !== "undefined" && markPos && props.exid == "okx") {
			okxPublicWebSocket()
		}
	}, [OKX_markPrice])

	useEffect(() => {
		if (OKX_openorder && tvReady && id !== "undefined" && props.exid == "okx") {
			setTimeout(() => {
				okxOpenOrderWebSocket()
			}, 2000)
		}
	}, [OKX_openorder, props.symbol])


	useEffect(() => {
		console.log("----------------------------------------------")
		console.log("tvStart")

		if (type === "1") {
			if (props.exid?.toLowerCase() === "binance") {
				dataFeedInstance = new BinanceDataFeed({});
				exId = "Binance";
			} else if (props.exid?.toLowerCase() === "okx") {
				dataFeedInstance = new OKXDataFeed({});
				exId = "OKX";
			} else if (props.exid?.toLowerCase() === "bybit") {
				dataFeedInstance = new BybitDataFeed({});
				exId = "Bybit";
			} else {
				dataFeedInstance = new BinanceDataFeed({});
				exId = "Binance";
			}
		} else if (type === "2") {
			if (props.exid?.toLowerCase() === "binance") {
				dataFeedInstance = new BinanceFuturesDataFeed({});
				exId = "Binance";
			} else if (props.exid?.toLowerCase() === "okx") {
				dataFeedInstance = new OKXDataSwapFeed({});
				exId = "OKX";
			} else if (props.exid?.toLowerCase() === "bybit") {
				dataFeedInstance = new BybitFuturesDataFeed({});
				exId = "Bybit";
			} else {
				dataFeedInstance = new BinanceFuturesDataFeed({});
				exId = "Binance";
			}
		} else {
			dataFeedInstance = new BinanceDataFeed({});
		}


		const lang = props.lang ?? "en";
		const saveChartURL = props.host === "chart.alp.kr" ? "https://save-chart.alp.kr" : "https://save-chart.rf-rev.com";
		API_URL = props.host !== "chart.rf-rev.com" ? "api.coinrf.io" : "api.namubit.com";

		let mode: any = props.mode;

		let interval = localStorage.getItem(`RF-interval-${symbol}`) ?? "1D";
		let modeColor = mode === "dark" ? "#000000" : "#FFFFFF";
		backColor = mode === "dark" ? API_Address == "rf" ? "#1f2029" : "#101014" : "#FFF";

		let loadChart = true;
		if (id === "undefined") {
			loadChart = false;
		}

		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: `${exId}:${symbol}`,
			theme: mode,
			datafeed: dataFeedInstance,
			interval: interval as ResolutionString,
			container: chartContainerRef.current,
			library_path: props.library_path,
			locale: lang as LanguageCode,
			loading_screen: { backgroundColor: modeColor, foregroundColor: modeColor },
			disabled_features: ["popup_hints", "header_symbol_search"],
			enabled_features: ["items_favoriting", "save_chart_properties_to_local_storage", "saveload_separate_drawings_storage"],
			charts_storage_url: saveChartURL,
			charts_storage_api_version: props.charts_storage_api_version,
			// save_load_adapter: props.save_load_adapter,
			client_id: "RF-Rev Trading",
			user_id: id,
			fullscreen: props.fullscreen,
			autosize: props.autosize,
			timezone: "Etc/UTC" as CustomTimezones,
			favorites: {
				intervals: [],
				chartTypes: [],
			},
			load_last_chart: loadChart,
			// load_last_chart: false,
			auto_save_delay: 2,
			custom_css_url: './index.css',
		};

		tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				tvWidget.changeTheme(mode);
				if (API_Address == "dev") {
					if (mode == "dark") {
						setTimeout(() => {
							tvWidget.applyOverrides({
								"paneProperties.background": "#101014",
								"paneProperties.backgroundType": "solid",
								"paneProperties.vertGridProperties.color": "#cccccc16",
								"paneProperties.horzGridProperties.color": "#cccccc16"
							});
						}, 1)

						tvWidget.setCSSCustomProperty('--tv-color-platform-background', '#000000');
						tvWidget.setCSSCustomProperty('--tv-color-pane-background', '#101014');
						tvWidget.setCSSCustomProperty('--tv-color-popup-background', '#101014');
						tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-hover', '#101014');
						tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-expanded', '#101014');
						tvWidget.setCSSCustomProperty('--tv-color-popup-element-text-active', '#d1d4dc');
						tvWidget.setCSSCustomProperty('--tv-color-popup-element-background-active', '#2a2e39');
					}
					else {
						tvWidget.setCSSCustomProperty('--tv-color-platform-background', ' #f5f7fa');
						tvWidget.setCSSCustomProperty('--tv-color-pane-background', '#ffffff');
						tvWidget.setCSSCustomProperty('--tv-color-popup-background', '#ffffff');
						tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-hover', '#ffffff');
						tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-background-expanded', '#ffffff');
						tvWidget.setCSSCustomProperty('--tv-color-popup-element-text-active', '#131722');
						tvWidget.setCSSCustomProperty('--tv-color-popup-element-background-active', '#f0f3fa');

					}
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-text-hover', '#F5AC1D');
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-text-active', '#F5AC1D');
					tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-text-active-hover', '#F5AC1D');
				}

				// let toolbar = localStorage.getItem("tradingview.ChartDrawingToolbarWidget.visible")
				// if (!toolbar || toolbar == "true") {
				// 	tvWidget.activeChart().executeActionById("drawingToolbarAction");
				// }

				setIsChartReady(true);
				const priceScale = tvWidget.chart().getPanes()[0].getRightPriceScales()[0];
				priceScale.setMode(1);

				setTimeout(function () {
					if (id !== "undefined" && id !== "1") {
						if (type === "2") {
							position = tvWidget.chart().createPositionLine().setPrice(0);
							liqPos = tvWidget.chart().createPositionLine().setPrice(0);
							markPos = tvWidget.chart().createPositionLine().setPrice(0);
						}
					}
				}, 0);

				setTVReady(true)

				if (id !== "undefined" && id !== "1") {
					tvWidget.subscribe("onAutoSaveNeeded", () => {
						tvWidget.saveChartToServer(undefined, undefined, {
							chartName: `NAMU-CHART`,
						});
						localStorage.setItem(`RF-interval-${symbol}`, tvWidget.symbolInterval().interval);
					});
				}
			});
		});

		return () => {
			tvWidget.remove();
			setIsChartReady(false);
			setTVReady(false)
		};
	}, [props.lang]);

	const spinnerStyle: any = {
		position: "absolute",
		fontSize: "20px",
		fontWeight: "bold",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		backgroundColor: backColor
	};

	return (
		<>
			<div style={{ position: "relative", height: "100%" }}>
				{!isChartReady && (
					<div style={spinnerStyle}>
						<Loader color="#0019ff" size={50} loading={true} speedMultiplier={0.5} cssOverride={{ borderWidth: "5px" }} />
					</div>
				)}
				<div ref={chartContainerRef} className="TVChartContainer" />
			</div>
		</>
	);
};

// bybit 함수
async function bybitAmendOrder(symbol: any, orderId: any, price: any): Promise<any | null> {
	try {
		let objString = localStorage.getItem('token');
		if (objString) {
			let obj = JSON.parse(objString)
			let jwt = obj.value;
			const response = await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bybit/linear/order/amend", {
				symbol: symbol,
				orderId: orderId,
				price: price.toString()
			}, {
				headers: { Authorization: `Bearer ${jwt}` }
			})
			return response.data;
		}
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function bybitAlgoAmendOrder(symbol: any, orderId: any, triggerPrice: any): Promise<any | null> {
	try {
		let objString = localStorage.getItem('token');
		if (objString) {
			let obj = JSON.parse(objString)
			let jwt = obj.value;
			const response = await axios.post(process.env.REACT_APP_API + "/api/v1/trade/bybit/linear/order/amend", {
				symbol: symbol,
				orderId: orderId,
				triggerPrice: triggerPrice.toString()
			}, {
				headers: { Authorization: `Bearer ${jwt}` }
			})
			return response.data;
		}
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function bybitCancelOrder(symbol: any, orderId: any): Promise<any | null> {
	try {
		let objString = localStorage.getItem('token');
		if (objString) {
			let obj = JSON.parse(objString)
			let jwt = obj.value;
			const response = await axios.post(process.env.REACT_APP_API + "/api/v1" + "/trade/bybit/linear/order/cancel", {
				category: "linear",
				symbol: symbol,
				orderId: orderId
			}, {
				headers: { Authorization: `Bearer ${jwt}` }
			})
			return response.data;
		}
	} catch (err) {
		console.error(err);
		return null;
	}
}

// okx 함수
async function okxAmendOrder(id: any, instId: any, ordId: any, newPx: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/okx/swap/order/amend/chart?id=${id}&instId=${instId}&ordId=${ordId}&newPx=${newPx}`);
		// console.log(response);
		return response.data[0];
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function okxAlgoOrder(id: any, instId: any, tdMode: any, side: any, ordType: any, sz: any, triggerPx: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/okx/swap/algo/order/chart?id=${id}&instId=${instId}&tdMode=${tdMode}&side=${side}&ordType=${ordType}&sz=${sz}&triggerPx=${triggerPx}`);
		// console.log(response.data);
		return response.data[0];
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function okxCancelOrder(id: any, instId: any, ordId: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/okx/swap/cancel/order/chart?id=${id}&instId=${instId}&ordId=${ordId}`);
		// console.log(response.data);
		return response.data[0];
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function okxCancelAlgoOrder(id: any, instId: any, algoId: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/okx/swap/algo/order/cancel/chart?id=${id}&instId=${instId}&algoId=${algoId}`);
		// console.log(response.data);
		return response.data[0];
	} catch (err) {
		console.error(err);
		return null;
	}
}

// 바이낸스 함수
async function bnCancelOrder(id: any, symbol: any, orderId: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/bn/futures/cancel/order/chart?id=${id}&symbol=${symbol}&orderId=${orderId}`);
		return response.data.status;
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function bnModifyOrder(id: any, symbol: any, orderId: any, side: any, quantity: any, price: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/bn/futures/modify/order/chart?id=${id}&symbol=${symbol}&orderId=${orderId}&side=${side}&quantity=${quantity}&price=${price}`);
		return response.data;
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function bnOrder(id: any, symbol: any, side: any, type: any, reduceOnly: any, quantity: any, stopPrice: any, timeInForce: any): Promise<any | null> {
	try {
		const response = await axios.get(`https://${API_URL}/api/v1/trade/bn/futures/order/chart?id=${id}&symbol=${symbol}&type=${type}&side=${side}&quantity=${quantity}&stopPrice=${stopPrice}&timeInForce=${timeInForce}&reduceOnly=${reduceOnly}`);
		return response.data;
	} catch (err) {
		console.error(err);
		return null;
	}
}